import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import './PlaceIndexPage.css';
import { useDeletePlaceMutation, useGetPlaceQuery } from '../generated/graphql';
import { Loading } from '../Loading';
import { formatDate } from '../utils/DateHelper';
import { AuthButton } from '../components/Button';
import {
  ConfirmDialog,
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import { ToastContext } from '../components/Toast';

type IPlaceIndexPageProps = RouteComponentProps<{ placeId: string }>;

/**
 * 現場・基本情報ページ
 */
export const PlaceIndexPage: React.FC<IPlaceIndexPageProps> = function (props) {
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const placeId = parseInt(props.match.params.placeId);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const { data, loading, error } = useGetPlaceQuery({
    variables: { placeId },
  });
  const [deletePlace, deletePlaceResult] = useDeletePlaceMutation({
    onCompleted() {
      toastContext.showToast(
        '「' + data?.getPlace.name + '」を削除しました',
        'success',
        3000
      );
      history.push('/places');
    },
  });

  if (error) {
    return <GraphQLErrorDialog error={error} />;
  }

  if (deletePlaceResult.error) {
    return <GraphQLMutationErrorDialog error={deletePlaceResult.error} />;
  }

  if (loading || !data || deletePlaceResult.loading) {
    return <Loading />;
  }

  const place = data.getPlace;

  // TODO: created, updated
  return (
    <div className="PlaceIndexPage-container">
      {(function () {
        if (!confirmDelete) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            message={<p>{place.name}を削除しますか？</p>}
            onYes={() => deletePlace({ variables: { placeId } })}
            onNo={() => setConfirmDelete(false)}
          />
        );
      })()}
      <h3>責任者</h3>
      <p>{place.chief_name}</p>
      <h3>作業者一覧</h3>
      <table className="App-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>作業者名</th>
          </tr>
        </thead>
        <tbody>
          {place.authorized_workers.map((worker) => {
            return (
              <tr>
                <td>{worker.screen_id}</td>
                <td>{worker.name}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3>お客様</h3>
      <p>{place.client_name}</p>
      <h3>作成日</h3>
      <p>{formatDate(place.created)}</p>
      <h3>更新日</h3>
      <p>{formatDate(place.updated)}</p>
      <div className="PlaceIndexPage-button_container">
        <AuthButton
          authLv={2}
          onClick={() => history.push(`/places/${placeId}/edit`)}
        >
          物件情報変更
        </AuthButton>
      </div>
      <div className="PlaceIndexPage-button_container">
        <AuthButton
          authLv={2}
          onClick={() => setConfirmDelete(true)}
          color="red"
        >
          削除
        </AuthButton>
      </div>
    </div>
  );
};
