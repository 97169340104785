import * as React from 'react';
import { Checkbox, Input } from '../Input';
import { Button } from '../Button';
import { Validated } from '../Validated';
import { FieldType, IDrawingObject } from './DrawingObjectsInput';
import { NotifyDialog } from '../Dialogs';

export interface IOutputField {
  name: string;
  formula: string;
  unit: string;
  export: boolean;
}

function defaultOutputField(): IOutputField {
  return {
    name: '',
    formula: '',
    unit: '',
    export: false,
  };
}

interface OutputFieldsInputProps {
  value: IOutputField[];
  drawingObjects: IDrawingObject[];
  validation: string | undefined;
  onChange: (fields: IOutputField[]) => void;
}

export const OutputFieldsInput: React.FC<OutputFieldsInputProps> = function (
  props
) {
  const [canceled, setCanceled] = React.useState(false);

  function changeValue(index: number, field: IOutputField) {
    const next = props.value.slice();
    next[index] = field;
    props.onChange(next);
  }
  function addValue() {
    props.onChange([...props.value, defaultOutputField()]);
  }
  function removeValue(index: number) {
    // DrawingObject
    const changable = props.drawingObjects.every((obj) => {
      if (obj.fieldType !== FieldType.OutputField) {
        return true;
      }

      if (obj.fieldIndex !== index) {
        return true;
      }

      return false;
    });

    if (!changable) {
      setCanceled(true);
      return;
    }

    props.onChange(props.value.filter((_, i) => i != index));
  }

  function row(field: IOutputField, index: number) {
    return (
      <tr>
        <td>
          <Input
            value={field.name}
            onChange={(event) =>
              changeValue(index, { ...field, name: event.target.value })
            }
          />
        </td>
        <td>
          <Input
            value={field.formula}
            onChange={(event) =>
              changeValue(index, { ...field, formula: event.target.value })
            }
          />
        </td>
        <td>
          <Checkbox
            value={field.export}
            onChange={(value) =>
              changeValue(index, { ...field, export: value })
            }
          />
        </td>
        <td>
          <Input
            value={field.unit}
            onChange={(event) =>
              changeValue(index, { ...field, unit: event.target.value })
            }
          />
        </td>
        <td className="App-table_button_cell">
          <Button mini={true} color="red" onClick={() => removeValue(index)}>
            削除
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <Validated message={props.validation}>
      {(function () {
        if (!canceled) {
          return;
        }

        return (
          <NotifyDialog
            open={true}
            message={<p>作画文字列に参照されているため削除できません</p>}
            onYes={() => {
              setCanceled(false);
            }}
          />
        );
      })()}
      <table className="App-table">
        <thead>
          <tr>
            <th>名称</th>
            <th>計算式</th>
            <th>集計表に出力</th>
            <th>単位</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.value.map(row)}
          <tr>
            <td colSpan={5}>
              <Button onClick={() => addValue()}>追加</Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Validated>
  );
};
