import * as React from 'react';

interface AppContextAccount {
  id: number;
  screenId: string;
  name: string;
  department: string;
  authority: {
    level: number;
  };
  created: Date;
}

export interface AppContextAppInfo {
  companyName: string;
}

type Action =
  | { type: 'setAccount'; account: AppContextAccount }
  | { type: 'setAppInfo'; appInfo: AppContextAppInfo }
  | { type: 'removeAccount' };

export function appContextReducer(
  state: AppContextState,
  action: Action
): AppContextState {
  switch (action.type) {
    case 'setAccount':
      return { ...state, me: action.account };
    case 'removeAccount':
      return { ...state, me: undefined };
    case 'setAppInfo':
      return { ...state, appInfo: action.appInfo };
    default:
      return state;
  }
}

export interface AppContextState {
  me?: AppContextAccount;
  appInfo?: AppContextAppInfo;
}

interface IAppContext {
  state: AppContextState;
  dispatch: React.Dispatch<Action>;
}

export const AppContext = React.createContext<IAppContext>({
  state: {},
  dispatch() {
    throw new Error('AppContext is not initialized');
  },
});
