import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Unknown: any;
};



export type Query = {
  __typename?: 'Query';
  sayHello: Scalars['String'];
  getAppInfo: AppInfo;
  getMe: Account;
  getConfig: Config;
  getFrame: Frame;
  getShapeTile: ShapeTile;
  getPlaces: Array<Place>;
  getPlace: Place;
  getPlaceFace: Face;
  getPlaceCode: Code;
  getPlaceSort: Sort;
  getCustomers: Array<Customer>;
  getCustomer: Customer;
  getAccountByScreenId: Account;
  getAccounts: Array<Account>;
  getAccount: Account;
  getCodeMarkerDependencies: Array<Marker>;
  getSortMarkerDependencies: Array<Marker>;
};


export type QueryGetFrameArgs = {
  frame_id: Scalars['Int'];
};


export type QueryGetShapeTileArgs = {
  shape_tile_id: Scalars['Int'];
};


export type QueryGetPlaceArgs = {
  place_id: Scalars['Int'];
};


export type QueryGetPlaceFaceArgs = {
  face_id: Scalars['Int'];
};


export type QueryGetPlaceCodeArgs = {
  code_id: Scalars['Int'];
};


export type QueryGetPlaceSortArgs = {
  sort_id: Scalars['Int'];
};


export type QueryGetCustomerArgs = {
  client_id: Scalars['Int'];
};


export type QueryGetAccountByScreenIdArgs = {
  screen_id: Scalars['String'];
};


export type QueryGetAccountArgs = {
  account_id: Scalars['Int'];
};


export type QueryGetCodeMarkerDependenciesArgs = {
  code_id: Scalars['Int'];
};


export type QueryGetSortMarkerDependenciesArgs = {
  sort_id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateMyPassword: Scalars['Boolean'];
  updateFaceData: Face;
  syncPlaceMarkers: SyncedPlaceMarkers;
  deletePlaceMarker: Scalars['Boolean'];
  uploadFile: Scalars['String'];
  updateShapeTile: ShapeTile;
  deleteShapeTile: Scalars['Boolean'];
  updateFrame: Frame;
  deleteFrame: Scalars['Boolean'];
  updatePlace: Place;
  clonePlace: Place;
  deletePlace: Scalars['Boolean'];
  updatePlaceCode: Code;
  deletePlaceCode: Scalars['Boolean'];
  deletePlaceCodeInputFields: Scalars['Boolean'];
  deletePlaceCodeAdditionalFields: Scalars['Boolean'];
  deletePlaceCodeOutputFields: Scalars['Boolean'];
  deletePlaceCodeDrawingObjects: Scalars['Boolean'];
  updatePlaceFace: Face;
  clonePlaceFace: Face;
  deletePlaceFace: Scalars['Boolean'];
  deletePlaceFaceDxfImages: Scalars['Boolean'];
  uploadPlaceFacePicture?: Maybe<Picture>;
  deletePlaceFaceMarker: Scalars['Boolean'];
  updatePlaceSort: Sort;
  deletePlaceSort: Scalars['Boolean'];
  updateCustomer: Customer;
  deleteCustomer: Scalars['Boolean'];
  createAccountPlaceAuthority: Scalars['Boolean'];
  deleteAccountPlaceAuthority: Scalars['Boolean'];
  updateAccount: Account;
  updateAccountPassword: Scalars['Boolean'];
  deleteAccount: Scalars['Boolean'];
};


export type MutationUpdateMyPasswordArgs = {
  input: AccountPasswordInput;
};


export type MutationUpdateFaceDataArgs = {
  face_id: Scalars['Int'];
  data?: Maybe<Scalars['Unknown']>;
};


export type MutationSyncPlaceMarkersArgs = {
  input: SyncPlaceMarkersInput;
};


export type MutationDeletePlaceMarkerArgs = {
  marker_id: Scalars['Int'];
};


export type MutationUploadFileArgs = {
  file_data: Scalars['String'];
};


export type MutationUpdateShapeTileArgs = {
  input: ShapeTileInput;
};


export type MutationDeleteShapeTileArgs = {
  shape_tile_id: Scalars['Int'];
};


export type MutationUpdateFrameArgs = {
  input: FrameInput;
};


export type MutationDeleteFrameArgs = {
  frame_id: Scalars['Int'];
};


export type MutationUpdatePlaceArgs = {
  input: PlaceInput;
};


export type MutationClonePlaceArgs = {
  input: ClonePlaceInput;
};


export type MutationDeletePlaceArgs = {
  place_id: Scalars['Int'];
};


export type MutationUpdatePlaceCodeArgs = {
  input: PlaceCodeInput;
};


export type MutationDeletePlaceCodeArgs = {
  code_id: Scalars['Int'];
};


export type MutationDeletePlaceCodeInputFieldsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePlaceCodeAdditionalFieldsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePlaceCodeOutputFieldsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeletePlaceCodeDrawingObjectsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationUpdatePlaceFaceArgs = {
  input: PlaceFaceInput;
};


export type MutationClonePlaceFaceArgs = {
  face_id: Scalars['Int'];
};


export type MutationDeletePlaceFaceArgs = {
  face_id: Scalars['Int'];
};


export type MutationDeletePlaceFaceDxfImagesArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationUploadPlaceFacePictureArgs = {
  input?: Maybe<PictureInput>;
};


export type MutationDeletePlaceFaceMarkerArgs = {
  marker_id: Scalars['Int'];
};


export type MutationUpdatePlaceSortArgs = {
  input: PlaceSortInput;
};


export type MutationDeletePlaceSortArgs = {
  sort_id: Scalars['Int'];
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  client_id: Scalars['Int'];
};


export type MutationCreateAccountPlaceAuthorityArgs = {
  input: AccountPlaceAuthorityInput;
};


export type MutationDeleteAccountPlaceAuthorityArgs = {
  input: AccountPlaceAuthorityInput;
};


export type MutationUpdateAccountArgs = {
  input: AccountInput;
};


export type MutationUpdateAccountPasswordArgs = {
  input: AccountPasswordInput;
};


export type MutationDeleteAccountArgs = {
  account_id: Scalars['Int'];
};

export type AccountInput = {
  id?: Maybe<Scalars['Int']>;
  screen_id: Scalars['String'];
  name: Scalars['String'];
  department: Scalars['String'];
  auth_lv: Scalars['Int'];
};

export type AccountPasswordInput = {
  account_id: Scalars['Int'];
  password: Scalars['String'];
};

export type AccountPlaceAuthorityInput = {
  account_id: Scalars['Int'];
  place_id: Scalars['Int'];
};

export type ShapeTileInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  margin: Scalars['Int'];
};

export type FrameInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  frame_type: Scalars['Int'];
  dxf?: Maybe<DxfInput>;
  dxf_images: Array<DxfImageInput>;
  uploaded_dxf_images: Array<Scalars['Int']>;
};

export type PlaceInput = {
  id?: Maybe<Scalars['Int']>;
  customerId: Scalars['Int'];
  name: Scalars['String'];
  chief_name: Scalars['String'];
  authorized_worker_ids: Array<Scalars['Int']>;
};

export type ClonePlaceInput = {
  place_id: Scalars['Int'];
  name: Scalars['String'];
  codes: Scalars['Boolean'];
  sorts: Scalars['Boolean'];
  faces: Scalars['Boolean'];
};

export type FaceRevision = {
  __typename?: 'FaceRevision';
  face_id: Scalars['Int'];
  revision: Scalars['Int'];
};

export type SyncedPlaceMarkers = {
  __typename?: 'SyncedPlaceMarkers';
  face_revisions: Array<FaceRevision>;
  inserted: Array<Marker>;
  failed: Array<Marker>;
};

export type PlaceFaceMarkerInput = {
  id: Scalars['Int'];
  revision: Scalars['Int'];
  place_id: Scalars['Int'];
  face_id: Scalars['Int'];
  sort_id: Scalars['Int'];
  code_id: Scalars['Int'];
  deleted: Scalars['Boolean'];
  data: Scalars['Unknown'];
};

export type SyncPlaceMarkersInput = {
  markers: Array<PlaceFaceMarkerInput>;
};

export type PlaceCodeInput = {
  id?: Maybe<Scalars['Int']>;
  place_id: Scalars['Int'];
  name: Scalars['String'];
  shape_id: Scalars['Int'];
  shape_tile_id: Scalars['Int'];
  shape_symbol_id: Scalars['Int'];
  shape_line_id: Scalars['Int'];
  shape_hatch_id: Scalars['Int'];
  layers: Array<PlaceCodeLayerInput>;
  input_fields: Array<PlaceCodeInputFieldInput>;
  additional_fields: Array<PlaceCodeAdditionalFieldInput>;
  output_fields: Array<PlaceCodeOutputFieldInput>;
  drawing_objects: Array<PlaceCodeDrawingObjectInput>;
};

export type PlaceCodeLayerInput = {
  name: Scalars['String'];
};

export type PlaceCodeInputFieldInput = {
  id?: Maybe<Scalars['Int']>;
  input_field_scheme_id: Scalars['Int'];
  unit: Scalars['String'];
  export: Scalars['Boolean'];
};

export type PlaceCodeAdditionalFieldInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  unit: Scalars['String'];
  value_type: Scalars['Int'];
  options: Array<Scalars['String']>;
  export: Scalars['Boolean'];
};

export type PlaceCodeOutputFieldInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  formula: Scalars['String'];
  unit: Scalars['String'];
  export: Scalars['Boolean'];
};

export type PlaceCodeDrawingObjectInput = {
  id?: Maybe<Scalars['Int']>;
  field_type: Scalars['Int'];
  field_index: Scalars['Int'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
  leader_line_type: Scalars['Int'];
  leader_arrow_end_type: Scalars['Int'];
  digit: Scalars['Int'];
};

export type PlaceFaceInput = {
  id?: Maybe<Scalars['Int']>;
  place_id: Scalars['Int'];
  dxf?: Maybe<DxfInput>;
  dxf_images: Array<DxfImageInput>;
  uploaded_dxf_images: Array<Scalars['Int']>;
};

export type DxfInput = {
  file_name: Scalars['String'];
  file_url: Scalars['String'];
  file_size: Scalars['Int'];
};

export type DxfImageInput = {
  file_name: Scalars['String'];
  file_url: Scalars['String'];
};

export type PictureInput = {
  face_id: Scalars['Int'];
  id: Scalars['Int'];
  index: Scalars['Int'];
  name: Scalars['String'];
  file_data: Scalars['String'];
  thumbnail_file_data: Scalars['String'];
};

export type PlaceSortInput = {
  id?: Maybe<Scalars['Int']>;
  place_id: Scalars['Int'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type CustomerInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type AppInfo = {
  __typename?: 'AppInfo';
  company_name: Scalars['String'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  screen_id: Scalars['String'];
  name: Scalars['String'];
  department: Scalars['String'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  authority: Authority;
};

export type Authority = {
  __typename?: 'Authority';
  level: Scalars['Int'];
  can_data_access: Scalars['Boolean'];
  can_master_edit: Scalars['Boolean'];
  can_user_edit: Scalars['Boolean'];
};

export type Config = {
  __typename?: 'Config';
  frames: Array<Frame>;
  tiles: Array<ShapeTile>;
};

export type ShapeTile = {
  __typename?: 'ShapeTile';
  id: Scalars['Int'];
  name: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  margin: Scalars['Int'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type Frame = {
  __typename?: 'Frame';
  id: Scalars['Int'];
  name: Scalars['String'];
  frame_type: Scalars['Int'];
  dxf_file_name: Scalars['String'];
  dxf_url: Scalars['String'];
  dxf_file_size: Scalars['Int'];
  dxf_images: Array<DxfImage>;
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['Int'];
  name: Scalars['String'];
  chief_name: Scalars['String'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
  client_id: Scalars['Int'];
  client_name?: Maybe<Scalars['String']>;
  faces: Array<Face>;
  sorts: Array<Sort>;
  codes: Array<Code>;
  authorized_workers: Array<Account>;
};

export type Face = {
  __typename?: 'Face';
  id: Scalars['Int'];
  dxf_file_name: Scalars['String'];
  dxf_url: Scalars['String'];
  dxf_file_size: Scalars['Int'];
  revision: Scalars['Int'];
  dxf_images: Array<DxfImage>;
  markers: Array<Marker>;
  pictures: Array<Picture>;
  data: Scalars['Unknown'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type DxfImage = {
  __typename?: 'DxfImage';
  id: Scalars['Int'];
  file_name: Scalars['String'];
  file_url: Scalars['String'];
};

export type Picture = {
  __typename?: 'Picture';
  id: Scalars['Int'];
  index: Scalars['Int'];
  name: Scalars['String'];
  thumbnail_url: Scalars['String'];
  full_url: Scalars['String'];
  created: Scalars['Date'];
};

export type Marker = {
  __typename?: 'Marker';
  object_id: Scalars['Int'];
  id: Scalars['Int'];
  sort_id: Scalars['Int'];
  code_id: Scalars['Int'];
  user_id: Scalars['Int'];
  created: Scalars['Date'];
  revision: Scalars['Int'];
  deleted: Scalars['Boolean'];
  data: Scalars['Unknown'];
};

export type Sort = {
  __typename?: 'Sort';
  id: Scalars['Int'];
  name: Scalars['String'];
  color: Scalars['String'];
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type Code = {
  __typename?: 'Code';
  id: Scalars['Int'];
  name: Scalars['String'];
  shape_scheme_id: Scalars['Int'];
  shape_tile_scheme_id: Scalars['Int'];
  shape_symbol_scheme_id: Scalars['Int'];
  line_type: Scalars['Int'];
  hatch_type: Scalars['Int'];
  layers: Array<CodeLayerClass>;
  input_fields: Array<InputFieldClass>;
  additional_fields: Array<AdditionalFieldClass>;
  output_fields: Array<OutputFieldClass>;
  drawing_objects: Array<DrawingObjectClass>;
  created: Scalars['Date'];
  updated: Scalars['Date'];
};

export type CodeLayerClass = {
  __typename?: 'CodeLayerClass';
  name: Scalars['String'];
};

export type InputFieldClass = {
  __typename?: 'InputFieldClass';
  id: Scalars['Int'];
  input_field_scheme_id: Scalars['Int'];
  unit: Scalars['String'];
  export_to_summary: Scalars['Boolean'];
};

export type AdditionalFieldClass = {
  __typename?: 'AdditionalFieldClass';
  id: Scalars['Int'];
  name: Scalars['String'];
  unit: Scalars['String'];
  export_to_summary: Scalars['Boolean'];
  value_type: Scalars['Int'];
  options: Array<Scalars['String']>;
};

export type OutputFieldClass = {
  __typename?: 'OutputFieldClass';
  id: Scalars['Int'];
  name: Scalars['String'];
  formula: Scalars['String'];
  unit: Scalars['String'];
  export_to_summary: Scalars['Boolean'];
};

export type DrawingObjectClass = {
  __typename?: 'DrawingObjectClass';
  id: Scalars['Int'];
  field_type: Scalars['Int'];
  field_id: Scalars['Int'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
  leader_line_type: Scalars['Int'];
  leader_arrow_end_type: Scalars['Int'];
  digit: Scalars['Int'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['Int'];
  name: Scalars['String'];
  created: Scalars['Date'];
};

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { getMe: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'screen_id' | 'name' | 'department' | 'created' | 'updated'>
    & { authority: (
      { __typename?: 'Authority' }
      & Pick<Authority, 'level'>
    ) }
  ) }
);

export type GetConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigQuery = (
  { __typename?: 'Query' }
  & { getConfig: (
    { __typename?: 'Config' }
    & { frames: Array<(
      { __typename?: 'Frame' }
      & Pick<Frame, 'id' | 'name' | 'frame_type' | 'dxf_file_name' | 'dxf_url' | 'dxf_file_size' | 'created' | 'updated'>
      & { dxf_images: Array<(
        { __typename?: 'DxfImage' }
        & Pick<DxfImage, 'file_name' | 'file_url'>
      )> }
    )>, tiles: Array<(
      { __typename?: 'ShapeTile' }
      & Pick<ShapeTile, 'id' | 'name' | 'width' | 'height' | 'margin' | 'created' | 'updated'>
    )> }
  ) }
);

export type GetFrameQueryVariables = Exact<{
  frameId: Scalars['Int'];
}>;


export type GetFrameQuery = (
  { __typename?: 'Query' }
  & { getFrame: (
    { __typename?: 'Frame' }
    & Pick<Frame, 'id' | 'name' | 'frame_type' | 'dxf_file_name' | 'dxf_url' | 'dxf_file_size' | 'created' | 'updated'>
    & { dxf_images: Array<(
      { __typename?: 'DxfImage' }
      & Pick<DxfImage, 'id' | 'file_name' | 'file_url'>
    )> }
  ) }
);

export type UpdateFrameMutationVariables = Exact<{
  input: FrameInput;
}>;


export type UpdateFrameMutation = (
  { __typename?: 'Mutation' }
  & { updateFrame: (
    { __typename?: 'Frame' }
    & Pick<Frame, 'id' | 'name' | 'frame_type' | 'dxf_file_name' | 'dxf_url' | 'dxf_file_size' | 'created' | 'updated'>
  ) }
);

export type DeleteFrameMutationVariables = Exact<{
  frameId: Scalars['Int'];
}>;


export type DeleteFrameMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFrame'>
);

export type GetShapeTileQueryVariables = Exact<{
  shapeTileId: Scalars['Int'];
}>;


export type GetShapeTileQuery = (
  { __typename?: 'Query' }
  & { getShapeTile: (
    { __typename?: 'ShapeTile' }
    & Pick<ShapeTile, 'id' | 'name' | 'width' | 'height' | 'margin' | 'created' | 'updated'>
  ) }
);

export type UpdateShapeTileMutationVariables = Exact<{
  input: ShapeTileInput;
}>;


export type UpdateShapeTileMutation = (
  { __typename?: 'Mutation' }
  & { updateShapeTile: (
    { __typename?: 'ShapeTile' }
    & Pick<ShapeTile, 'id' | 'name' | 'width' | 'height' | 'margin'>
  ) }
);

export type DeleteShapeTileMutationVariables = Exact<{
  shapeTileId: Scalars['Int'];
}>;


export type DeleteShapeTileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteShapeTile'>
);

export type GetPlacesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlacesQuery = (
  { __typename?: 'Query' }
  & { getPlaces: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'chief_name' | 'client_name' | 'created' | 'updated'>
    & { faces: Array<(
      { __typename?: 'Face' }
      & Pick<Face, 'id'>
    )> }
  )> }
);

export type GetPlaceNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlaceNamesQuery = (
  { __typename?: 'Query' }
  & { getPlaces: Array<(
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name'>
  )> }
);

export type GetPlaceQueryVariables = Exact<{
  placeId: Scalars['Int'];
}>;


export type GetPlaceQuery = (
  { __typename?: 'Query' }
  & { getPlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name' | 'chief_name' | 'client_id' | 'client_name' | 'created' | 'updated'>
    & { authorized_workers: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'id' | 'screen_id' | 'name'>
    )>, sorts: Array<(
      { __typename?: 'Sort' }
      & Pick<Sort, 'id'>
    )>, codes: Array<(
      { __typename?: 'Code' }
      & Pick<Code, 'id'>
    )>, faces: Array<(
      { __typename?: 'Face' }
      & Pick<Face, 'id'>
    )> }
  ) }
);

export type ClonePlaceMutationVariables = Exact<{
  input: ClonePlaceInput;
}>;


export type ClonePlaceMutation = (
  { __typename?: 'Mutation' }
  & { clonePlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id'>
  ) }
);

export type UpdatePlaceMutationVariables = Exact<{
  input: PlaceInput;
}>;


export type UpdatePlaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id'>
  ) }
);

export type DeletePlaceMutationVariables = Exact<{
  placeId: Scalars['Int'];
}>;


export type DeletePlaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlace'>
);

export type GetPlaceFacesQueryVariables = Exact<{
  placeId: Scalars['Int'];
}>;


export type GetPlaceFacesQuery = (
  { __typename?: 'Query' }
  & { getPlace: (
    { __typename?: 'Place' }
    & { faces: Array<(
      { __typename?: 'Face' }
      & Pick<Face, 'id' | 'dxf_file_name' | 'dxf_url' | 'dxf_file_size' | 'created' | 'updated'>
      & { dxf_images: Array<(
        { __typename?: 'DxfImage' }
        & Pick<DxfImage, 'file_name'>
      )>, markers: Array<(
        { __typename?: 'Marker' }
        & Pick<Marker, 'id'>
      )>, pictures: Array<(
        { __typename?: 'Picture' }
        & Pick<Picture, 'index'>
      )> }
    )> }
  ) }
);

export type GetPlaceFaceQueryVariables = Exact<{
  placeFaceId: Scalars['Int'];
}>;


export type GetPlaceFaceQuery = (
  { __typename?: 'Query' }
  & { getPlaceFace: (
    { __typename?: 'Face' }
    & Pick<Face, 'id' | 'dxf_file_name' | 'dxf_url'>
    & { dxf_images: Array<(
      { __typename?: 'DxfImage' }
      & Pick<DxfImage, 'id' | 'file_name' | 'file_url'>
    )> }
  ) }
);

export type ClonePlaceFaceMutationVariables = Exact<{
  placeFaceId: Scalars['Int'];
}>;


export type ClonePlaceFaceMutation = (
  { __typename?: 'Mutation' }
  & { clonePlaceFace: (
    { __typename?: 'Face' }
    & Pick<Face, 'id'>
  ) }
);

export type GetPlaceFacePicturesQueryVariables = Exact<{
  placeFaceId: Scalars['Int'];
}>;


export type GetPlaceFacePicturesQuery = (
  { __typename?: 'Query' }
  & { getPlaceFace: (
    { __typename?: 'Face' }
    & { pictures: Array<(
      { __typename?: 'Picture' }
      & Pick<Picture, 'id' | 'index' | 'name' | 'thumbnail_url' | 'full_url' | 'created'>
    )> }
  ) }
);

export type DeletePlaceFaceMarkerMutationVariables = Exact<{
  markerId: Scalars['Int'];
}>;


export type DeletePlaceFaceMarkerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaceFaceMarker'>
);

export type GetPlaceFaceMarkersQueryVariables = Exact<{
  placeId: Scalars['Int'];
  placeFaceId: Scalars['Int'];
}>;


export type GetPlaceFaceMarkersQuery = (
  { __typename?: 'Query' }
  & { getPlace: (
    { __typename?: 'Place' }
    & { codes: Array<(
      { __typename?: 'Code' }
      & Pick<Code, 'id' | 'name'>
    )>, sorts: Array<(
      { __typename?: 'Sort' }
      & Pick<Sort, 'id' | 'name' | 'color'>
    )> }
  ), getAccounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'name'>
  )>, getPlaceFace: (
    { __typename?: 'Face' }
    & { markers: Array<(
      { __typename?: 'Marker' }
      & Pick<Marker, 'object_id' | 'id' | 'sort_id' | 'code_id' | 'user_id' | 'created' | 'revision'>
    )> }
  ) }
);

export type UpdatePlaceFaceMutationVariables = Exact<{
  input: PlaceFaceInput;
}>;


export type UpdatePlaceFaceMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaceFace: (
    { __typename?: 'Face' }
    & Pick<Face, 'id'>
  ) }
);

export type DeletePlaceFaceMutationVariables = Exact<{
  faceId: Scalars['Int'];
}>;


export type DeletePlaceFaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaceFace'>
);

export type GetPlaceCodesQueryVariables = Exact<{
  placeId: Scalars['Int'];
}>;


export type GetPlaceCodesQuery = (
  { __typename?: 'Query' }
  & { getConfig: (
    { __typename?: 'Config' }
    & { tiles: Array<(
      { __typename?: 'ShapeTile' }
      & Pick<ShapeTile, 'id' | 'name'>
    )> }
  ), getPlace: (
    { __typename?: 'Place' }
    & Pick<Place, 'id' | 'name'>
    & { codes: Array<(
      { __typename?: 'Code' }
      & Pick<Code, 'id' | 'name' | 'shape_scheme_id' | 'shape_tile_scheme_id' | 'shape_symbol_scheme_id'>
    )> }
  ) }
);

export type GetPlaceCodeQueryVariables = Exact<{
  placeCodeId: Scalars['Int'];
}>;


export type GetPlaceCodeQuery = (
  { __typename?: 'Query' }
  & { getPlaceCode: (
    { __typename?: 'Code' }
    & Pick<Code, 'id' | 'name' | 'shape_scheme_id' | 'shape_tile_scheme_id' | 'shape_symbol_scheme_id' | 'line_type' | 'hatch_type'>
    & { layers: Array<(
      { __typename?: 'CodeLayerClass' }
      & Pick<CodeLayerClass, 'name'>
    )>, input_fields: Array<(
      { __typename?: 'InputFieldClass' }
      & Pick<InputFieldClass, 'id' | 'input_field_scheme_id' | 'unit' | 'export_to_summary'>
    )>, additional_fields: Array<(
      { __typename?: 'AdditionalFieldClass' }
      & Pick<AdditionalFieldClass, 'id' | 'name' | 'unit' | 'value_type' | 'options' | 'export_to_summary'>
    )>, output_fields: Array<(
      { __typename?: 'OutputFieldClass' }
      & Pick<OutputFieldClass, 'id' | 'name' | 'formula' | 'unit' | 'export_to_summary'>
    )>, drawing_objects: Array<(
      { __typename?: 'DrawingObjectClass' }
      & Pick<DrawingObjectClass, 'id' | 'field_type' | 'field_id' | 'prefix' | 'suffix' | 'leader_line_type' | 'leader_arrow_end_type' | 'digit'>
    )> }
  ) }
);

export type UpdatePlaceCodeMutationVariables = Exact<{
  input: PlaceCodeInput;
}>;


export type UpdatePlaceCodeMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaceCode: (
    { __typename?: 'Code' }
    & Pick<Code, 'id'>
  ) }
);

export type DeletePlaceCodeMutationVariables = Exact<{
  placeCodeId: Scalars['Int'];
}>;


export type DeletePlaceCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaceCode'>
);

export type GetPlaceCodeMarkerDependenciesQueryVariables = Exact<{
  placeCodeId: Scalars['Int'];
}>;


export type GetPlaceCodeMarkerDependenciesQuery = (
  { __typename?: 'Query' }
  & { getCodeMarkerDependencies: Array<(
    { __typename?: 'Marker' }
    & Pick<Marker, 'id' | 'revision' | 'deleted'>
  )> }
);

export type GetPlaceSortsQueryVariables = Exact<{
  placeId: Scalars['Int'];
}>;


export type GetPlaceSortsQuery = (
  { __typename?: 'Query' }
  & { getPlace: (
    { __typename?: 'Place' }
    & { sorts: Array<(
      { __typename?: 'Sort' }
      & Pick<Sort, 'id' | 'name' | 'color' | 'created' | 'updated'>
    )> }
  ) }
);

export type GetPlaceSortQueryVariables = Exact<{
  placeSortId: Scalars['Int'];
}>;


export type GetPlaceSortQuery = (
  { __typename?: 'Query' }
  & { getPlaceSort: (
    { __typename?: 'Sort' }
    & Pick<Sort, 'id' | 'name' | 'color' | 'created' | 'updated'>
  ) }
);

export type UpdatePlaceSortMutationVariables = Exact<{
  input: PlaceSortInput;
}>;


export type UpdatePlaceSortMutation = (
  { __typename?: 'Mutation' }
  & { updatePlaceSort: (
    { __typename?: 'Sort' }
    & Pick<Sort, 'id'>
  ) }
);

export type DeletePlaceSortMutationVariables = Exact<{
  placeSortId: Scalars['Int'];
}>;


export type DeletePlaceSortMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePlaceSort'>
);

export type GetPlaceSortMarkerDependenciesQueryVariables = Exact<{
  placeSortId: Scalars['Int'];
}>;


export type GetPlaceSortMarkerDependenciesQuery = (
  { __typename?: 'Query' }
  & { getSortMarkerDependencies: Array<(
    { __typename?: 'Marker' }
    & Pick<Marker, 'id' | 'deleted' | 'revision'>
  )> }
);

export type GetAdminDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminDataQuery = (
  { __typename?: 'Query' }
  & { getAccounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'screen_id' | 'name' | 'department'>
    & { authority: (
      { __typename?: 'Authority' }
      & Pick<Authority, 'level'>
    ) }
  )>, getCustomers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )> }
);

export type GetAccountQueryVariables = Exact<{
  accountId: Scalars['Int'];
}>;


export type GetAccountQuery = (
  { __typename?: 'Query' }
  & { getAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'screen_id' | 'name' | 'department'>
    & { authority: (
      { __typename?: 'Authority' }
      & Pick<Authority, 'level'>
    ) }
  ) }
);

export type GetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { getAccounts: Array<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'screen_id' | 'name' | 'department'>
    & { authority: (
      { __typename?: 'Authority' }
      & Pick<Authority, 'level'>
    ) }
  )> }
);

export type UpdateAccountMutationVariables = Exact<{
  input: AccountInput;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateAccount: (
    { __typename?: 'Account' }
    & Pick<Account, 'id'>
  ) }
);

export type UpdateAccountPasswordMutationVariables = Exact<{
  input: AccountPasswordInput;
}>;


export type UpdateAccountPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAccountPassword'>
);

export type UpdateAccountMyPasswordMutationVariables = Exact<{
  input: AccountPasswordInput;
}>;


export type UpdateAccountMyPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyPassword'>
);

export type DeleteAccountMutationVariables = Exact<{
  accountId: Scalars['Int'];
}>;


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccount'>
);

export type GetCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { getCustomers: Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  )> }
);

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ) }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name'>
  ) }
);

export type DeleteCustomerMutationVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type DeleteCustomerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCustomer'>
);


export const GetMeDocument = gql`
    query GetMe {
  getMe {
    id
    screen_id
    name
    department
    authority {
      level
    }
    created
    updated
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetConfigDocument = gql`
    query GetConfig {
  getConfig {
    frames {
      id
      name
      frame_type
      dxf_file_name
      dxf_url
      dxf_file_size
      dxf_images {
        file_name
        file_url
      }
      created
      updated
    }
    tiles {
      id
      name
      width
      height
      margin
      created
      updated
    }
  }
}
    `;

/**
 * __useGetConfigQuery__
 *
 * To run a query within a React component, call `useGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigQuery, GetConfigQueryVariables>) {
        return Apollo.useQuery<GetConfigQuery, GetConfigQueryVariables>(GetConfigDocument, baseOptions);
      }
export function useGetConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigQuery, GetConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetConfigQuery, GetConfigQueryVariables>(GetConfigDocument, baseOptions);
        }
export type GetConfigQueryHookResult = ReturnType<typeof useGetConfigQuery>;
export type GetConfigLazyQueryHookResult = ReturnType<typeof useGetConfigLazyQuery>;
export type GetConfigQueryResult = Apollo.QueryResult<GetConfigQuery, GetConfigQueryVariables>;
export const GetFrameDocument = gql`
    query GetFrame($frameId: Int!) {
  getFrame(frame_id: $frameId) {
    id
    name
    frame_type
    dxf_file_name
    dxf_url
    dxf_file_size
    dxf_images {
      id
      file_name
      file_url
    }
    created
    updated
  }
}
    `;

/**
 * __useGetFrameQuery__
 *
 * To run a query within a React component, call `useGetFrameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrameQuery({
 *   variables: {
 *      frameId: // value for 'frameId'
 *   },
 * });
 */
export function useGetFrameQuery(baseOptions: Apollo.QueryHookOptions<GetFrameQuery, GetFrameQueryVariables>) {
        return Apollo.useQuery<GetFrameQuery, GetFrameQueryVariables>(GetFrameDocument, baseOptions);
      }
export function useGetFrameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrameQuery, GetFrameQueryVariables>) {
          return Apollo.useLazyQuery<GetFrameQuery, GetFrameQueryVariables>(GetFrameDocument, baseOptions);
        }
export type GetFrameQueryHookResult = ReturnType<typeof useGetFrameQuery>;
export type GetFrameLazyQueryHookResult = ReturnType<typeof useGetFrameLazyQuery>;
export type GetFrameQueryResult = Apollo.QueryResult<GetFrameQuery, GetFrameQueryVariables>;
export const UpdateFrameDocument = gql`
    mutation UpdateFrame($input: FrameInput!) {
  updateFrame(input: $input) {
    id
    name
    frame_type
    dxf_file_name
    dxf_url
    dxf_file_size
    created
    updated
  }
}
    `;
export type UpdateFrameMutationFn = Apollo.MutationFunction<UpdateFrameMutation, UpdateFrameMutationVariables>;

/**
 * __useUpdateFrameMutation__
 *
 * To run a mutation, you first call `useUpdateFrameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFrameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFrameMutation, { data, loading, error }] = useUpdateFrameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFrameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFrameMutation, UpdateFrameMutationVariables>) {
        return Apollo.useMutation<UpdateFrameMutation, UpdateFrameMutationVariables>(UpdateFrameDocument, baseOptions);
      }
export type UpdateFrameMutationHookResult = ReturnType<typeof useUpdateFrameMutation>;
export type UpdateFrameMutationResult = Apollo.MutationResult<UpdateFrameMutation>;
export type UpdateFrameMutationOptions = Apollo.BaseMutationOptions<UpdateFrameMutation, UpdateFrameMutationVariables>;
export const DeleteFrameDocument = gql`
    mutation DeleteFrame($frameId: Int!) {
  deleteFrame(frame_id: $frameId)
}
    `;
export type DeleteFrameMutationFn = Apollo.MutationFunction<DeleteFrameMutation, DeleteFrameMutationVariables>;

/**
 * __useDeleteFrameMutation__
 *
 * To run a mutation, you first call `useDeleteFrameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFrameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFrameMutation, { data, loading, error }] = useDeleteFrameMutation({
 *   variables: {
 *      frameId: // value for 'frameId'
 *   },
 * });
 */
export function useDeleteFrameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFrameMutation, DeleteFrameMutationVariables>) {
        return Apollo.useMutation<DeleteFrameMutation, DeleteFrameMutationVariables>(DeleteFrameDocument, baseOptions);
      }
export type DeleteFrameMutationHookResult = ReturnType<typeof useDeleteFrameMutation>;
export type DeleteFrameMutationResult = Apollo.MutationResult<DeleteFrameMutation>;
export type DeleteFrameMutationOptions = Apollo.BaseMutationOptions<DeleteFrameMutation, DeleteFrameMutationVariables>;
export const GetShapeTileDocument = gql`
    query GetShapeTile($shapeTileId: Int!) {
  getShapeTile(shape_tile_id: $shapeTileId) {
    id
    name
    width
    height
    margin
    created
    updated
  }
}
    `;

/**
 * __useGetShapeTileQuery__
 *
 * To run a query within a React component, call `useGetShapeTileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShapeTileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShapeTileQuery({
 *   variables: {
 *      shapeTileId: // value for 'shapeTileId'
 *   },
 * });
 */
export function useGetShapeTileQuery(baseOptions: Apollo.QueryHookOptions<GetShapeTileQuery, GetShapeTileQueryVariables>) {
        return Apollo.useQuery<GetShapeTileQuery, GetShapeTileQueryVariables>(GetShapeTileDocument, baseOptions);
      }
export function useGetShapeTileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShapeTileQuery, GetShapeTileQueryVariables>) {
          return Apollo.useLazyQuery<GetShapeTileQuery, GetShapeTileQueryVariables>(GetShapeTileDocument, baseOptions);
        }
export type GetShapeTileQueryHookResult = ReturnType<typeof useGetShapeTileQuery>;
export type GetShapeTileLazyQueryHookResult = ReturnType<typeof useGetShapeTileLazyQuery>;
export type GetShapeTileQueryResult = Apollo.QueryResult<GetShapeTileQuery, GetShapeTileQueryVariables>;
export const UpdateShapeTileDocument = gql`
    mutation UpdateShapeTile($input: ShapeTileInput!) {
  updateShapeTile(input: $input) {
    id
    name
    width
    height
    margin
  }
}
    `;
export type UpdateShapeTileMutationFn = Apollo.MutationFunction<UpdateShapeTileMutation, UpdateShapeTileMutationVariables>;

/**
 * __useUpdateShapeTileMutation__
 *
 * To run a mutation, you first call `useUpdateShapeTileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShapeTileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShapeTileMutation, { data, loading, error }] = useUpdateShapeTileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShapeTileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShapeTileMutation, UpdateShapeTileMutationVariables>) {
        return Apollo.useMutation<UpdateShapeTileMutation, UpdateShapeTileMutationVariables>(UpdateShapeTileDocument, baseOptions);
      }
export type UpdateShapeTileMutationHookResult = ReturnType<typeof useUpdateShapeTileMutation>;
export type UpdateShapeTileMutationResult = Apollo.MutationResult<UpdateShapeTileMutation>;
export type UpdateShapeTileMutationOptions = Apollo.BaseMutationOptions<UpdateShapeTileMutation, UpdateShapeTileMutationVariables>;
export const DeleteShapeTileDocument = gql`
    mutation DeleteShapeTile($shapeTileId: Int!) {
  deleteShapeTile(shape_tile_id: $shapeTileId)
}
    `;
export type DeleteShapeTileMutationFn = Apollo.MutationFunction<DeleteShapeTileMutation, DeleteShapeTileMutationVariables>;

/**
 * __useDeleteShapeTileMutation__
 *
 * To run a mutation, you first call `useDeleteShapeTileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShapeTileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShapeTileMutation, { data, loading, error }] = useDeleteShapeTileMutation({
 *   variables: {
 *      shapeTileId: // value for 'shapeTileId'
 *   },
 * });
 */
export function useDeleteShapeTileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShapeTileMutation, DeleteShapeTileMutationVariables>) {
        return Apollo.useMutation<DeleteShapeTileMutation, DeleteShapeTileMutationVariables>(DeleteShapeTileDocument, baseOptions);
      }
export type DeleteShapeTileMutationHookResult = ReturnType<typeof useDeleteShapeTileMutation>;
export type DeleteShapeTileMutationResult = Apollo.MutationResult<DeleteShapeTileMutation>;
export type DeleteShapeTileMutationOptions = Apollo.BaseMutationOptions<DeleteShapeTileMutation, DeleteShapeTileMutationVariables>;
export const GetPlacesDocument = gql`
    query GetPlaces {
  getPlaces {
    id
    name
    chief_name
    client_name
    created
    updated
    faces {
      id
    }
  }
}
    `;

/**
 * __useGetPlacesQuery__
 *
 * To run a query within a React component, call `useGetPlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlacesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlacesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
        return Apollo.useQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, baseOptions);
      }
export function useGetPlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlacesQuery, GetPlacesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlacesQuery, GetPlacesQueryVariables>(GetPlacesDocument, baseOptions);
        }
export type GetPlacesQueryHookResult = ReturnType<typeof useGetPlacesQuery>;
export type GetPlacesLazyQueryHookResult = ReturnType<typeof useGetPlacesLazyQuery>;
export type GetPlacesQueryResult = Apollo.QueryResult<GetPlacesQuery, GetPlacesQueryVariables>;
export const GetPlaceNamesDocument = gql`
    query GetPlaceNames {
  getPlaces {
    id
    name
  }
}
    `;

/**
 * __useGetPlaceNamesQuery__
 *
 * To run a query within a React component, call `useGetPlaceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaceNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetPlaceNamesQuery, GetPlaceNamesQueryVariables>) {
        return Apollo.useQuery<GetPlaceNamesQuery, GetPlaceNamesQueryVariables>(GetPlaceNamesDocument, baseOptions);
      }
export function useGetPlaceNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceNamesQuery, GetPlaceNamesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceNamesQuery, GetPlaceNamesQueryVariables>(GetPlaceNamesDocument, baseOptions);
        }
export type GetPlaceNamesQueryHookResult = ReturnType<typeof useGetPlaceNamesQuery>;
export type GetPlaceNamesLazyQueryHookResult = ReturnType<typeof useGetPlaceNamesLazyQuery>;
export type GetPlaceNamesQueryResult = Apollo.QueryResult<GetPlaceNamesQuery, GetPlaceNamesQueryVariables>;
export const GetPlaceDocument = gql`
    query GetPlace($placeId: Int!) {
  getPlace(place_id: $placeId) {
    id
    name
    chief_name
    client_id
    client_name
    authorized_workers {
      id
      screen_id
      name
    }
    sorts {
      id
    }
    codes {
      id
    }
    faces {
      id
    }
    created
    updated
  }
}
    `;

/**
 * __useGetPlaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
        return Apollo.useQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, baseOptions);
      }
export function useGetPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceQuery, GetPlaceQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceQuery, GetPlaceQueryVariables>(GetPlaceDocument, baseOptions);
        }
export type GetPlaceQueryHookResult = ReturnType<typeof useGetPlaceQuery>;
export type GetPlaceLazyQueryHookResult = ReturnType<typeof useGetPlaceLazyQuery>;
export type GetPlaceQueryResult = Apollo.QueryResult<GetPlaceQuery, GetPlaceQueryVariables>;
export const ClonePlaceDocument = gql`
    mutation ClonePlace($input: ClonePlaceInput!) {
  clonePlace(input: $input) {
    id
  }
}
    `;
export type ClonePlaceMutationFn = Apollo.MutationFunction<ClonePlaceMutation, ClonePlaceMutationVariables>;

/**
 * __useClonePlaceMutation__
 *
 * To run a mutation, you first call `useClonePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClonePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clonePlaceMutation, { data, loading, error }] = useClonePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClonePlaceMutation(baseOptions?: Apollo.MutationHookOptions<ClonePlaceMutation, ClonePlaceMutationVariables>) {
        return Apollo.useMutation<ClonePlaceMutation, ClonePlaceMutationVariables>(ClonePlaceDocument, baseOptions);
      }
export type ClonePlaceMutationHookResult = ReturnType<typeof useClonePlaceMutation>;
export type ClonePlaceMutationResult = Apollo.MutationResult<ClonePlaceMutation>;
export type ClonePlaceMutationOptions = Apollo.BaseMutationOptions<ClonePlaceMutation, ClonePlaceMutationVariables>;
export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($input: PlaceInput!) {
  updatePlace(input: $input) {
    id
  }
}
    `;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, baseOptions);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;
export const DeletePlaceDocument = gql`
    mutation DeletePlace($placeId: Int!) {
  deletePlace(place_id: $placeId)
}
    `;
export type DeletePlaceMutationFn = Apollo.MutationFunction<DeletePlaceMutation, DeletePlaceMutationVariables>;

/**
 * __useDeletePlaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceMutation, { data, loading, error }] = useDeletePlaceMutation({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useDeletePlaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceMutation, DeletePlaceMutationVariables>) {
        return Apollo.useMutation<DeletePlaceMutation, DeletePlaceMutationVariables>(DeletePlaceDocument, baseOptions);
      }
export type DeletePlaceMutationHookResult = ReturnType<typeof useDeletePlaceMutation>;
export type DeletePlaceMutationResult = Apollo.MutationResult<DeletePlaceMutation>;
export type DeletePlaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceMutation, DeletePlaceMutationVariables>;
export const GetPlaceFacesDocument = gql`
    query GetPlaceFaces($placeId: Int!) {
  getPlace(place_id: $placeId) {
    faces {
      id
      dxf_file_name
      dxf_url
      dxf_file_size
      dxf_images {
        file_name
      }
      markers {
        id
      }
      pictures {
        index
      }
      created
      updated
    }
  }
}
    `;

/**
 * __useGetPlaceFacesQuery__
 *
 * To run a query within a React component, call `useGetPlaceFacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceFacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceFacesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceFacesQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceFacesQuery, GetPlaceFacesQueryVariables>) {
        return Apollo.useQuery<GetPlaceFacesQuery, GetPlaceFacesQueryVariables>(GetPlaceFacesDocument, baseOptions);
      }
export function useGetPlaceFacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceFacesQuery, GetPlaceFacesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceFacesQuery, GetPlaceFacesQueryVariables>(GetPlaceFacesDocument, baseOptions);
        }
export type GetPlaceFacesQueryHookResult = ReturnType<typeof useGetPlaceFacesQuery>;
export type GetPlaceFacesLazyQueryHookResult = ReturnType<typeof useGetPlaceFacesLazyQuery>;
export type GetPlaceFacesQueryResult = Apollo.QueryResult<GetPlaceFacesQuery, GetPlaceFacesQueryVariables>;
export const GetPlaceFaceDocument = gql`
    query GetPlaceFace($placeFaceId: Int!) {
  getPlaceFace(face_id: $placeFaceId) {
    id
    dxf_file_name
    dxf_url
    dxf_images {
      id
      file_name
      file_url
    }
  }
}
    `;

/**
 * __useGetPlaceFaceQuery__
 *
 * To run a query within a React component, call `useGetPlaceFaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceFaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceFaceQuery({
 *   variables: {
 *      placeFaceId: // value for 'placeFaceId'
 *   },
 * });
 */
export function useGetPlaceFaceQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceFaceQuery, GetPlaceFaceQueryVariables>) {
        return Apollo.useQuery<GetPlaceFaceQuery, GetPlaceFaceQueryVariables>(GetPlaceFaceDocument, baseOptions);
      }
export function useGetPlaceFaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceFaceQuery, GetPlaceFaceQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceFaceQuery, GetPlaceFaceQueryVariables>(GetPlaceFaceDocument, baseOptions);
        }
export type GetPlaceFaceQueryHookResult = ReturnType<typeof useGetPlaceFaceQuery>;
export type GetPlaceFaceLazyQueryHookResult = ReturnType<typeof useGetPlaceFaceLazyQuery>;
export type GetPlaceFaceQueryResult = Apollo.QueryResult<GetPlaceFaceQuery, GetPlaceFaceQueryVariables>;
export const ClonePlaceFaceDocument = gql`
    mutation ClonePlaceFace($placeFaceId: Int!) {
  clonePlaceFace(face_id: $placeFaceId) {
    id
  }
}
    `;
export type ClonePlaceFaceMutationFn = Apollo.MutationFunction<ClonePlaceFaceMutation, ClonePlaceFaceMutationVariables>;

/**
 * __useClonePlaceFaceMutation__
 *
 * To run a mutation, you first call `useClonePlaceFaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClonePlaceFaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clonePlaceFaceMutation, { data, loading, error }] = useClonePlaceFaceMutation({
 *   variables: {
 *      placeFaceId: // value for 'placeFaceId'
 *   },
 * });
 */
export function useClonePlaceFaceMutation(baseOptions?: Apollo.MutationHookOptions<ClonePlaceFaceMutation, ClonePlaceFaceMutationVariables>) {
        return Apollo.useMutation<ClonePlaceFaceMutation, ClonePlaceFaceMutationVariables>(ClonePlaceFaceDocument, baseOptions);
      }
export type ClonePlaceFaceMutationHookResult = ReturnType<typeof useClonePlaceFaceMutation>;
export type ClonePlaceFaceMutationResult = Apollo.MutationResult<ClonePlaceFaceMutation>;
export type ClonePlaceFaceMutationOptions = Apollo.BaseMutationOptions<ClonePlaceFaceMutation, ClonePlaceFaceMutationVariables>;
export const GetPlaceFacePicturesDocument = gql`
    query GetPlaceFacePictures($placeFaceId: Int!) {
  getPlaceFace(face_id: $placeFaceId) {
    pictures {
      id
      index
      name
      thumbnail_url
      full_url
      created
    }
  }
}
    `;

/**
 * __useGetPlaceFacePicturesQuery__
 *
 * To run a query within a React component, call `useGetPlaceFacePicturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceFacePicturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceFacePicturesQuery({
 *   variables: {
 *      placeFaceId: // value for 'placeFaceId'
 *   },
 * });
 */
export function useGetPlaceFacePicturesQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceFacePicturesQuery, GetPlaceFacePicturesQueryVariables>) {
        return Apollo.useQuery<GetPlaceFacePicturesQuery, GetPlaceFacePicturesQueryVariables>(GetPlaceFacePicturesDocument, baseOptions);
      }
export function useGetPlaceFacePicturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceFacePicturesQuery, GetPlaceFacePicturesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceFacePicturesQuery, GetPlaceFacePicturesQueryVariables>(GetPlaceFacePicturesDocument, baseOptions);
        }
export type GetPlaceFacePicturesQueryHookResult = ReturnType<typeof useGetPlaceFacePicturesQuery>;
export type GetPlaceFacePicturesLazyQueryHookResult = ReturnType<typeof useGetPlaceFacePicturesLazyQuery>;
export type GetPlaceFacePicturesQueryResult = Apollo.QueryResult<GetPlaceFacePicturesQuery, GetPlaceFacePicturesQueryVariables>;
export const DeletePlaceFaceMarkerDocument = gql`
    mutation DeletePlaceFaceMarker($markerId: Int!) {
  deletePlaceFaceMarker(marker_id: $markerId)
}
    `;
export type DeletePlaceFaceMarkerMutationFn = Apollo.MutationFunction<DeletePlaceFaceMarkerMutation, DeletePlaceFaceMarkerMutationVariables>;

/**
 * __useDeletePlaceFaceMarkerMutation__
 *
 * To run a mutation, you first call `useDeletePlaceFaceMarkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceFaceMarkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceFaceMarkerMutation, { data, loading, error }] = useDeletePlaceFaceMarkerMutation({
 *   variables: {
 *      markerId: // value for 'markerId'
 *   },
 * });
 */
export function useDeletePlaceFaceMarkerMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceFaceMarkerMutation, DeletePlaceFaceMarkerMutationVariables>) {
        return Apollo.useMutation<DeletePlaceFaceMarkerMutation, DeletePlaceFaceMarkerMutationVariables>(DeletePlaceFaceMarkerDocument, baseOptions);
      }
export type DeletePlaceFaceMarkerMutationHookResult = ReturnType<typeof useDeletePlaceFaceMarkerMutation>;
export type DeletePlaceFaceMarkerMutationResult = Apollo.MutationResult<DeletePlaceFaceMarkerMutation>;
export type DeletePlaceFaceMarkerMutationOptions = Apollo.BaseMutationOptions<DeletePlaceFaceMarkerMutation, DeletePlaceFaceMarkerMutationVariables>;
export const GetPlaceFaceMarkersDocument = gql`
    query GetPlaceFaceMarkers($placeId: Int!, $placeFaceId: Int!) {
  getPlace(place_id: $placeId) {
    codes {
      id
      name
    }
    sorts {
      id
      name
      color
    }
  }
  getAccounts {
    id
    name
  }
  getPlaceFace(face_id: $placeFaceId) {
    markers {
      object_id
      id
      sort_id
      code_id
      user_id
      created
      revision
    }
  }
}
    `;

/**
 * __useGetPlaceFaceMarkersQuery__
 *
 * To run a query within a React component, call `useGetPlaceFaceMarkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceFaceMarkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceFaceMarkersQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *      placeFaceId: // value for 'placeFaceId'
 *   },
 * });
 */
export function useGetPlaceFaceMarkersQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceFaceMarkersQuery, GetPlaceFaceMarkersQueryVariables>) {
        return Apollo.useQuery<GetPlaceFaceMarkersQuery, GetPlaceFaceMarkersQueryVariables>(GetPlaceFaceMarkersDocument, baseOptions);
      }
export function useGetPlaceFaceMarkersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceFaceMarkersQuery, GetPlaceFaceMarkersQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceFaceMarkersQuery, GetPlaceFaceMarkersQueryVariables>(GetPlaceFaceMarkersDocument, baseOptions);
        }
export type GetPlaceFaceMarkersQueryHookResult = ReturnType<typeof useGetPlaceFaceMarkersQuery>;
export type GetPlaceFaceMarkersLazyQueryHookResult = ReturnType<typeof useGetPlaceFaceMarkersLazyQuery>;
export type GetPlaceFaceMarkersQueryResult = Apollo.QueryResult<GetPlaceFaceMarkersQuery, GetPlaceFaceMarkersQueryVariables>;
export const UpdatePlaceFaceDocument = gql`
    mutation UpdatePlaceFace($input: PlaceFaceInput!) {
  updatePlaceFace(input: $input) {
    id
  }
}
    `;
export type UpdatePlaceFaceMutationFn = Apollo.MutationFunction<UpdatePlaceFaceMutation, UpdatePlaceFaceMutationVariables>;

/**
 * __useUpdatePlaceFaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceFaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceFaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceFaceMutation, { data, loading, error }] = useUpdatePlaceFaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceFaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceFaceMutation, UpdatePlaceFaceMutationVariables>) {
        return Apollo.useMutation<UpdatePlaceFaceMutation, UpdatePlaceFaceMutationVariables>(UpdatePlaceFaceDocument, baseOptions);
      }
export type UpdatePlaceFaceMutationHookResult = ReturnType<typeof useUpdatePlaceFaceMutation>;
export type UpdatePlaceFaceMutationResult = Apollo.MutationResult<UpdatePlaceFaceMutation>;
export type UpdatePlaceFaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceFaceMutation, UpdatePlaceFaceMutationVariables>;
export const DeletePlaceFaceDocument = gql`
    mutation DeletePlaceFace($faceId: Int!) {
  deletePlaceFace(face_id: $faceId)
}
    `;
export type DeletePlaceFaceMutationFn = Apollo.MutationFunction<DeletePlaceFaceMutation, DeletePlaceFaceMutationVariables>;

/**
 * __useDeletePlaceFaceMutation__
 *
 * To run a mutation, you first call `useDeletePlaceFaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceFaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceFaceMutation, { data, loading, error }] = useDeletePlaceFaceMutation({
 *   variables: {
 *      faceId: // value for 'faceId'
 *   },
 * });
 */
export function useDeletePlaceFaceMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceFaceMutation, DeletePlaceFaceMutationVariables>) {
        return Apollo.useMutation<DeletePlaceFaceMutation, DeletePlaceFaceMutationVariables>(DeletePlaceFaceDocument, baseOptions);
      }
export type DeletePlaceFaceMutationHookResult = ReturnType<typeof useDeletePlaceFaceMutation>;
export type DeletePlaceFaceMutationResult = Apollo.MutationResult<DeletePlaceFaceMutation>;
export type DeletePlaceFaceMutationOptions = Apollo.BaseMutationOptions<DeletePlaceFaceMutation, DeletePlaceFaceMutationVariables>;
export const GetPlaceCodesDocument = gql`
    query GetPlaceCodes($placeId: Int!) {
  getConfig {
    tiles {
      id
      name
    }
  }
  getPlace(place_id: $placeId) {
    id
    name
    codes {
      id
      name
      shape_scheme_id
      shape_tile_scheme_id
      shape_symbol_scheme_id
    }
  }
}
    `;

/**
 * __useGetPlaceCodesQuery__
 *
 * To run a query within a React component, call `useGetPlaceCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceCodesQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceCodesQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceCodesQuery, GetPlaceCodesQueryVariables>) {
        return Apollo.useQuery<GetPlaceCodesQuery, GetPlaceCodesQueryVariables>(GetPlaceCodesDocument, baseOptions);
      }
export function useGetPlaceCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceCodesQuery, GetPlaceCodesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceCodesQuery, GetPlaceCodesQueryVariables>(GetPlaceCodesDocument, baseOptions);
        }
export type GetPlaceCodesQueryHookResult = ReturnType<typeof useGetPlaceCodesQuery>;
export type GetPlaceCodesLazyQueryHookResult = ReturnType<typeof useGetPlaceCodesLazyQuery>;
export type GetPlaceCodesQueryResult = Apollo.QueryResult<GetPlaceCodesQuery, GetPlaceCodesQueryVariables>;
export const GetPlaceCodeDocument = gql`
    query GetPlaceCode($placeCodeId: Int!) {
  getPlaceCode(code_id: $placeCodeId) {
    id
    name
    shape_scheme_id
    shape_tile_scheme_id
    shape_symbol_scheme_id
    line_type
    hatch_type
    layers {
      name
    }
    input_fields {
      id
      input_field_scheme_id
      unit
      export_to_summary
    }
    additional_fields {
      id
      name
      unit
      value_type
      options
      export_to_summary
    }
    output_fields {
      id
      name
      formula
      unit
      export_to_summary
    }
    drawing_objects {
      id
      field_type
      field_id
      prefix
      suffix
      leader_line_type
      leader_arrow_end_type
      digit
    }
  }
}
    `;

/**
 * __useGetPlaceCodeQuery__
 *
 * To run a query within a React component, call `useGetPlaceCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceCodeQuery({
 *   variables: {
 *      placeCodeId: // value for 'placeCodeId'
 *   },
 * });
 */
export function useGetPlaceCodeQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceCodeQuery, GetPlaceCodeQueryVariables>) {
        return Apollo.useQuery<GetPlaceCodeQuery, GetPlaceCodeQueryVariables>(GetPlaceCodeDocument, baseOptions);
      }
export function useGetPlaceCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceCodeQuery, GetPlaceCodeQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceCodeQuery, GetPlaceCodeQueryVariables>(GetPlaceCodeDocument, baseOptions);
        }
export type GetPlaceCodeQueryHookResult = ReturnType<typeof useGetPlaceCodeQuery>;
export type GetPlaceCodeLazyQueryHookResult = ReturnType<typeof useGetPlaceCodeLazyQuery>;
export type GetPlaceCodeQueryResult = Apollo.QueryResult<GetPlaceCodeQuery, GetPlaceCodeQueryVariables>;
export const UpdatePlaceCodeDocument = gql`
    mutation UpdatePlaceCode($input: PlaceCodeInput!) {
  updatePlaceCode(input: $input) {
    id
  }
}
    `;
export type UpdatePlaceCodeMutationFn = Apollo.MutationFunction<UpdatePlaceCodeMutation, UpdatePlaceCodeMutationVariables>;

/**
 * __useUpdatePlaceCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceCodeMutation, { data, loading, error }] = useUpdatePlaceCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceCodeMutation, UpdatePlaceCodeMutationVariables>) {
        return Apollo.useMutation<UpdatePlaceCodeMutation, UpdatePlaceCodeMutationVariables>(UpdatePlaceCodeDocument, baseOptions);
      }
export type UpdatePlaceCodeMutationHookResult = ReturnType<typeof useUpdatePlaceCodeMutation>;
export type UpdatePlaceCodeMutationResult = Apollo.MutationResult<UpdatePlaceCodeMutation>;
export type UpdatePlaceCodeMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceCodeMutation, UpdatePlaceCodeMutationVariables>;
export const DeletePlaceCodeDocument = gql`
    mutation DeletePlaceCode($placeCodeId: Int!) {
  deletePlaceCode(code_id: $placeCodeId)
}
    `;
export type DeletePlaceCodeMutationFn = Apollo.MutationFunction<DeletePlaceCodeMutation, DeletePlaceCodeMutationVariables>;

/**
 * __useDeletePlaceCodeMutation__
 *
 * To run a mutation, you first call `useDeletePlaceCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceCodeMutation, { data, loading, error }] = useDeletePlaceCodeMutation({
 *   variables: {
 *      placeCodeId: // value for 'placeCodeId'
 *   },
 * });
 */
export function useDeletePlaceCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceCodeMutation, DeletePlaceCodeMutationVariables>) {
        return Apollo.useMutation<DeletePlaceCodeMutation, DeletePlaceCodeMutationVariables>(DeletePlaceCodeDocument, baseOptions);
      }
export type DeletePlaceCodeMutationHookResult = ReturnType<typeof useDeletePlaceCodeMutation>;
export type DeletePlaceCodeMutationResult = Apollo.MutationResult<DeletePlaceCodeMutation>;
export type DeletePlaceCodeMutationOptions = Apollo.BaseMutationOptions<DeletePlaceCodeMutation, DeletePlaceCodeMutationVariables>;
export const GetPlaceCodeMarkerDependenciesDocument = gql`
    query GetPlaceCodeMarkerDependencies($placeCodeId: Int!) {
  getCodeMarkerDependencies(code_id: $placeCodeId) {
    id
    revision
    deleted
  }
}
    `;

/**
 * __useGetPlaceCodeMarkerDependenciesQuery__
 *
 * To run a query within a React component, call `useGetPlaceCodeMarkerDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceCodeMarkerDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceCodeMarkerDependenciesQuery({
 *   variables: {
 *      placeCodeId: // value for 'placeCodeId'
 *   },
 * });
 */
export function useGetPlaceCodeMarkerDependenciesQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceCodeMarkerDependenciesQuery, GetPlaceCodeMarkerDependenciesQueryVariables>) {
        return Apollo.useQuery<GetPlaceCodeMarkerDependenciesQuery, GetPlaceCodeMarkerDependenciesQueryVariables>(GetPlaceCodeMarkerDependenciesDocument, baseOptions);
      }
export function useGetPlaceCodeMarkerDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceCodeMarkerDependenciesQuery, GetPlaceCodeMarkerDependenciesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceCodeMarkerDependenciesQuery, GetPlaceCodeMarkerDependenciesQueryVariables>(GetPlaceCodeMarkerDependenciesDocument, baseOptions);
        }
export type GetPlaceCodeMarkerDependenciesQueryHookResult = ReturnType<typeof useGetPlaceCodeMarkerDependenciesQuery>;
export type GetPlaceCodeMarkerDependenciesLazyQueryHookResult = ReturnType<typeof useGetPlaceCodeMarkerDependenciesLazyQuery>;
export type GetPlaceCodeMarkerDependenciesQueryResult = Apollo.QueryResult<GetPlaceCodeMarkerDependenciesQuery, GetPlaceCodeMarkerDependenciesQueryVariables>;
export const GetPlaceSortsDocument = gql`
    query GetPlaceSorts($placeId: Int!) {
  getPlace(place_id: $placeId) {
    sorts {
      id
      name
      color
      created
      updated
    }
  }
}
    `;

/**
 * __useGetPlaceSortsQuery__
 *
 * To run a query within a React component, call `useGetPlaceSortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceSortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceSortsQuery({
 *   variables: {
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useGetPlaceSortsQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceSortsQuery, GetPlaceSortsQueryVariables>) {
        return Apollo.useQuery<GetPlaceSortsQuery, GetPlaceSortsQueryVariables>(GetPlaceSortsDocument, baseOptions);
      }
export function useGetPlaceSortsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceSortsQuery, GetPlaceSortsQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceSortsQuery, GetPlaceSortsQueryVariables>(GetPlaceSortsDocument, baseOptions);
        }
export type GetPlaceSortsQueryHookResult = ReturnType<typeof useGetPlaceSortsQuery>;
export type GetPlaceSortsLazyQueryHookResult = ReturnType<typeof useGetPlaceSortsLazyQuery>;
export type GetPlaceSortsQueryResult = Apollo.QueryResult<GetPlaceSortsQuery, GetPlaceSortsQueryVariables>;
export const GetPlaceSortDocument = gql`
    query GetPlaceSort($placeSortId: Int!) {
  getPlaceSort(sort_id: $placeSortId) {
    id
    name
    color
    created
    updated
  }
}
    `;

/**
 * __useGetPlaceSortQuery__
 *
 * To run a query within a React component, call `useGetPlaceSortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceSortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceSortQuery({
 *   variables: {
 *      placeSortId: // value for 'placeSortId'
 *   },
 * });
 */
export function useGetPlaceSortQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceSortQuery, GetPlaceSortQueryVariables>) {
        return Apollo.useQuery<GetPlaceSortQuery, GetPlaceSortQueryVariables>(GetPlaceSortDocument, baseOptions);
      }
export function useGetPlaceSortLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceSortQuery, GetPlaceSortQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceSortQuery, GetPlaceSortQueryVariables>(GetPlaceSortDocument, baseOptions);
        }
export type GetPlaceSortQueryHookResult = ReturnType<typeof useGetPlaceSortQuery>;
export type GetPlaceSortLazyQueryHookResult = ReturnType<typeof useGetPlaceSortLazyQuery>;
export type GetPlaceSortQueryResult = Apollo.QueryResult<GetPlaceSortQuery, GetPlaceSortQueryVariables>;
export const UpdatePlaceSortDocument = gql`
    mutation UpdatePlaceSort($input: PlaceSortInput!) {
  updatePlaceSort(input: $input) {
    id
  }
}
    `;
export type UpdatePlaceSortMutationFn = Apollo.MutationFunction<UpdatePlaceSortMutation, UpdatePlaceSortMutationVariables>;

/**
 * __useUpdatePlaceSortMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceSortMutation, { data, loading, error }] = useUpdatePlaceSortMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlaceSortMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceSortMutation, UpdatePlaceSortMutationVariables>) {
        return Apollo.useMutation<UpdatePlaceSortMutation, UpdatePlaceSortMutationVariables>(UpdatePlaceSortDocument, baseOptions);
      }
export type UpdatePlaceSortMutationHookResult = ReturnType<typeof useUpdatePlaceSortMutation>;
export type UpdatePlaceSortMutationResult = Apollo.MutationResult<UpdatePlaceSortMutation>;
export type UpdatePlaceSortMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceSortMutation, UpdatePlaceSortMutationVariables>;
export const DeletePlaceSortDocument = gql`
    mutation DeletePlaceSort($placeSortId: Int!) {
  deletePlaceSort(sort_id: $placeSortId)
}
    `;
export type DeletePlaceSortMutationFn = Apollo.MutationFunction<DeletePlaceSortMutation, DeletePlaceSortMutationVariables>;

/**
 * __useDeletePlaceSortMutation__
 *
 * To run a mutation, you first call `useDeletePlaceSortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlaceSortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlaceSortMutation, { data, loading, error }] = useDeletePlaceSortMutation({
 *   variables: {
 *      placeSortId: // value for 'placeSortId'
 *   },
 * });
 */
export function useDeletePlaceSortMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlaceSortMutation, DeletePlaceSortMutationVariables>) {
        return Apollo.useMutation<DeletePlaceSortMutation, DeletePlaceSortMutationVariables>(DeletePlaceSortDocument, baseOptions);
      }
export type DeletePlaceSortMutationHookResult = ReturnType<typeof useDeletePlaceSortMutation>;
export type DeletePlaceSortMutationResult = Apollo.MutationResult<DeletePlaceSortMutation>;
export type DeletePlaceSortMutationOptions = Apollo.BaseMutationOptions<DeletePlaceSortMutation, DeletePlaceSortMutationVariables>;
export const GetPlaceSortMarkerDependenciesDocument = gql`
    query GetPlaceSortMarkerDependencies($placeSortId: Int!) {
  getSortMarkerDependencies(sort_id: $placeSortId) {
    id
    deleted
    revision
  }
}
    `;

/**
 * __useGetPlaceSortMarkerDependenciesQuery__
 *
 * To run a query within a React component, call `useGetPlaceSortMarkerDependenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaceSortMarkerDependenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaceSortMarkerDependenciesQuery({
 *   variables: {
 *      placeSortId: // value for 'placeSortId'
 *   },
 * });
 */
export function useGetPlaceSortMarkerDependenciesQuery(baseOptions: Apollo.QueryHookOptions<GetPlaceSortMarkerDependenciesQuery, GetPlaceSortMarkerDependenciesQueryVariables>) {
        return Apollo.useQuery<GetPlaceSortMarkerDependenciesQuery, GetPlaceSortMarkerDependenciesQueryVariables>(GetPlaceSortMarkerDependenciesDocument, baseOptions);
      }
export function useGetPlaceSortMarkerDependenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaceSortMarkerDependenciesQuery, GetPlaceSortMarkerDependenciesQueryVariables>) {
          return Apollo.useLazyQuery<GetPlaceSortMarkerDependenciesQuery, GetPlaceSortMarkerDependenciesQueryVariables>(GetPlaceSortMarkerDependenciesDocument, baseOptions);
        }
export type GetPlaceSortMarkerDependenciesQueryHookResult = ReturnType<typeof useGetPlaceSortMarkerDependenciesQuery>;
export type GetPlaceSortMarkerDependenciesLazyQueryHookResult = ReturnType<typeof useGetPlaceSortMarkerDependenciesLazyQuery>;
export type GetPlaceSortMarkerDependenciesQueryResult = Apollo.QueryResult<GetPlaceSortMarkerDependenciesQuery, GetPlaceSortMarkerDependenciesQueryVariables>;
export const GetAdminDataDocument = gql`
    query GetAdminData {
  getAccounts {
    id
    screen_id
    name
    department
    authority {
      level
    }
  }
  getCustomers {
    id
    name
  }
}
    `;

/**
 * __useGetAdminDataQuery__
 *
 * To run a query within a React component, call `useGetAdminDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminDataQuery, GetAdminDataQueryVariables>) {
        return Apollo.useQuery<GetAdminDataQuery, GetAdminDataQueryVariables>(GetAdminDataDocument, baseOptions);
      }
export function useGetAdminDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminDataQuery, GetAdminDataQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminDataQuery, GetAdminDataQueryVariables>(GetAdminDataDocument, baseOptions);
        }
export type GetAdminDataQueryHookResult = ReturnType<typeof useGetAdminDataQuery>;
export type GetAdminDataLazyQueryHookResult = ReturnType<typeof useGetAdminDataLazyQuery>;
export type GetAdminDataQueryResult = Apollo.QueryResult<GetAdminDataQuery, GetAdminDataQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($accountId: Int!) {
  getAccount(account_id: $accountId) {
    id
    screen_id
    name
    department
    authority {
      level
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, baseOptions);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts {
  getAccounts {
    id
    screen_id
    name
    department
    authority {
      level
    }
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($input: AccountInput!) {
  updateAccount(input: $input) {
    id
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const UpdateAccountPasswordDocument = gql`
    mutation UpdateAccountPassword($input: AccountPasswordInput!) {
  updateAccountPassword(input: $input)
}
    `;
export type UpdateAccountPasswordMutationFn = Apollo.MutationFunction<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;

/**
 * __useUpdateAccountPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPasswordMutation, { data, loading, error }] = useUpdateAccountPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>(UpdateAccountPasswordDocument, baseOptions);
      }
export type UpdateAccountPasswordMutationHookResult = ReturnType<typeof useUpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationResult = Apollo.MutationResult<UpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;
export const UpdateAccountMyPasswordDocument = gql`
    mutation UpdateAccountMyPassword($input: AccountPasswordInput!) {
  updateMyPassword(input: $input)
}
    `;
export type UpdateAccountMyPasswordMutationFn = Apollo.MutationFunction<UpdateAccountMyPasswordMutation, UpdateAccountMyPasswordMutationVariables>;

/**
 * __useUpdateAccountMyPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMyPasswordMutation, { data, loading, error }] = useUpdateAccountMyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMyPasswordMutation, UpdateAccountMyPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateAccountMyPasswordMutation, UpdateAccountMyPasswordMutationVariables>(UpdateAccountMyPasswordDocument, baseOptions);
      }
export type UpdateAccountMyPasswordMutationHookResult = ReturnType<typeof useUpdateAccountMyPasswordMutation>;
export type UpdateAccountMyPasswordMutationResult = Apollo.MutationResult<UpdateAccountMyPasswordMutation>;
export type UpdateAccountMyPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMyPasswordMutation, UpdateAccountMyPasswordMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($accountId: Int!) {
  deleteAccount(account_id: $accountId)
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const GetCustomersDocument = gql`
    query GetCustomers {
  getCustomers {
    id
    name
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetCustomerDocument = gql`
    query GetCustomer($customerId: Int!) {
  getCustomer(client_id: $customerId) {
    id
    name
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    id
    name
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation DeleteCustomer($customerId: Int!) {
  deleteCustomer(client_id: $customerId)
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, baseOptions);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;