import dateformat from 'dateformat';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Const from '../Const';
import { useGetPlaceFacePicturesQuery } from '../generated/graphql';
import { Loading } from '../Loading';
import './PlaceDxfPicturesPage.css';
import path from 'path';

type PlaceDxfPicturesPage = RouteComponentProps<{
  placeId: string;
  placeFaceId: string;
}>;

export const PlaceDxfPicturesPage: React.FC<PlaceDxfPicturesPage> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const placeFaceId = parseInt(props.match.params.placeFaceId);

  const getPictures = useGetPlaceFacePicturesQuery({
    variables: { placeFaceId },
  });

  if (getPictures.loading || !getPictures.data) {
    return <Loading />;
  }

  function withExtension(url: string): string {
    if (path.extname(url) === '') {
      url += '.jpg?noext=true&download';
    } else {
      url += '?download';
    }
    return Const.API_URL + url;
  }

  return (
    <div className="PlaceDxfListPage-container">
      <table className="App-table">
        <thead>
          <tr>
            <th>番号</th>
            <th>名前</th>
            <th>画像</th>
            <th>日付</th>
          </tr>
        </thead>
        <tbody>
          {getPictures.data.getPlaceFace.pictures.map((picture) => {
            return (
              <tr>
                <td>{picture.index}</td>
                <td>{picture.name}</td>
                <td>
                  <a href={withExtension(picture.full_url)}>
                    <img
                      className="PlaceDxfPicturesPage-thumbnail"
                      src={Const.API_URL + picture.thumbnail_url}
                    />
                  </a>
                </td>
                <td>{dateformat(picture.created, 'yyyy年m月d日 HH:MM:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
