import * as React from 'react';
import { AppContext } from './AppContext';
import './stylesheets/Header.css';
/**
 * アプリケーションのヘッダー
 */
export const Header: React.FC = function () {
  const appContext = React.useContext(AppContext);

  if (!appContext.state.appInfo) {
    return (
      <header className="Header-header">
        <div className="Header-icon App-icon">
          <img alt="App Icon" src="/appicon.png" />
        </div>
        <div className="Header-title App-title">S-Cloud</div>
        <div className="Header-navigator"></div>
      </header>
    );
  }

  if (!appContext.state.me) {
    return (
      <header className="Header-header">
        <div className="Header-title App-title">S-Cloud</div>
        <div className="Header-navigator">{appContext.state.appInfo.companyName}</div>
      </header>
    );
  }

  return (
    <header className="Header-header">
      <div className="Header-title App-title">S-Cloud</div>
      <div className="Header-navigator">
        <span>{appContext.state.appInfo.companyName}</span>
        <span className="Header-account_separator">·</span>
        <span className="Header-account_name">{appContext.state.me.name}</span>
        <span className="Header-account_san">さん</span>
      </div>
    </header>
  );
};
