import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as React from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router';
import { AppContext } from './AppContext';
import * as Const from './Const';
import * as graphApi from './generated/graphql';
import { Loading } from './Loading';
import { FatalErrorDialog, GraphQLErrorDialog } from './components/Dialogs';
// import { ToastContext } from './components/Toast';

const apolloClientCache = new InMemoryCache();

export const Auth: React.FC = function (props) {
  let [apolloClient, setApolloClient] = React.useState<
    ApolloClient<NormalizedCacheObject>
  >();
  const appContext = React.useContext(AppContext);
  // const toastContext = React.useContext(ToastContext);
  const [getMe, getMeResult] = graphApi.useGetMeLazyQuery({
    client: apolloClient,
    onCompleted() {
      // toastContext.showToast('ログインしました', 'info', 3000);
    },
  });

  if (!props.children) {
    throw new Error('Auth element must have children');
  }

  const cookies = new Cookies();
  const authToken = cookies.get('auth-token');
  if (!authToken) {
    return <Redirect to="/login" />;
  }

  if (getMeResult.error) {
    return <FatalErrorDialog />;
  }

  if (!apolloClient) {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    apolloClient = new ApolloClient({
      cache: apolloClientCache,
      defaultOptions: {
        watchQuery: {
          // このオプションでページ入るたびにクエリを取得するようになる
          fetchPolicy: 'network-only',
        },
      },
      link: ApolloLink.from([
        errorLink,
        new HttpLink({
          headers: {
            'x-account-auth-token': authToken,
          },
          uri: Const.API_URL + '/graphql',
        }),
      ]),
    });

    setApolloClient(apolloClient);
  }

  if (!appContext.state.me) {
    if (!getMeResult.called) {
      getMe();
    }

    if (getMeResult.error != undefined) {
      return <GraphQLErrorDialog error={getMeResult.error} />;
    }

    if (getMeResult.loading || !getMeResult.data) {
      return <Loading />;
    }

    const me = getMeResult.data.getMe;
    appContext.dispatch({
      type: 'setAccount',
      account: {
        id: me.id,
        screenId: me.screen_id,
        name: me.name,
        department: me.department,
        authority: {
          level: me.authority.level,
        },
        created: me.created,
      },
    });

    return <Loading />;
  }

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
