import * as React from 'react';
import dateformat from 'dateformat';
import './PlaceListPage.css';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../Loading';
import { useGetPlacesQuery } from '../generated/graphql';
import { GraphQLErrorDialog } from '../components/Dialogs';
import { AuthButton, Button } from '../components/Button';

export const PlaceListPage: React.FC = function () {
  const { loading, data, error } = useGetPlacesQuery();
  const history = useHistory();

  if (error != undefined) {
    return <GraphQLErrorDialog error={error} />;
  }

  if (loading || !data) {
    return <Loading />;
  }

  const places = data.getPlaces;

  return (
    <div className="PlaceListPage-container">
      <h1>物件一覧</h1>
      <AuthButton authLv={2} onClick={() => history.push('/places/new')}>
        新規作成
      </AuthButton>
      <table className="App-table">
        <thead>
          <tr>
            <th>現場名</th>
            <th>面数</th>
            <th>責任者</th>
            <th>お客様</th>
            <th>作成日</th>
            <th>更新日</th>
          </tr>
        </thead>
        <tbody>
          {places.map((place) => {
            if (!place) {
              return new Error();
            }
            return (
              <tr>
                <td>
                  <Link to={`/places/${place.id}`}>{place.name}</Link>
                </td>
                <td>{place.faces.length}</td>
                <td>{place.chief_name}</td>
                <td>{place.client_name}</td>
                <td>{dateformat(place.created, 'yyyy年m月d日 HH:MM:ss')}</td>
                <td>{dateformat(place.updated, 'yyyy年m月d日 HH:MM:ss')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
