import React from 'react';
import './Toast.css';

type ToastType = 'info' | 'error' | 'success';

let toastId = 0;

interface ToastContextToast {
  id: number;
  type: ToastType;
  message: string;
}

interface ToastContextState {
  toasts: ToastContextToast[];
}

type Action =
  | { type: 'addToast'; id: number; toastType: ToastType; message: string }
  | { type: 'deleteToast'; id: number };

export function toastContextReducer(
  state: ToastContextState,
  action: Action
): ToastContextState {
  switch (action.type) {
    case 'addToast':
      return {
        toasts: [
          ...state.toasts,
          { id: action.id, type: action.toastType, message: action.message },
        ],
      };
    case 'deleteToast':
      return {
        toasts: state.toasts.filter((t) => t.id != action.id),
      };
    default:
      return state;
  }
}

export class ToastContextClass {
  private readonly provider: () => ToastContextData;

  constructor(provider: () => ToastContextData) {
    this.provider = provider;
  }

  getToasts() {
    const { state } = this.provider();
    if (state) {
      return state.toasts;
    }

    throw new Error('ToastContext is not initialized');
  }

  showToast(message: string, toastType: ToastType, timeout?: number): number {
    const { dispatch } = this.provider();

    if (dispatch) {
      const id = toastId++;
      dispatch({ type: 'addToast', id, toastType, message });

      if (timeout) {
        setTimeout(() => dispatch({ type: 'deleteToast', id }), timeout);
      }

      return toastId;
    }

    throw new Error('ToastContext is not initialized');
  }

  hideToast(id: number) {
    const { dispatch } = this.provider();

    if (dispatch) {
      dispatch({ type: 'deleteToast', id });
      return;
    }

    throw new Error('ToastContext is not initialized');
  }
}

export const ToastContext = React.createContext<ToastContextClass>(
  new ToastContextClass(function () {
    return {
      state: { toasts: [] },
      dispatch() {},
    };
  })
);

export interface ToastContextData {
  state: ToastContextState;
  dispatch: React.Dispatch<Action>;
}

interface ToastProps {
  toast: ToastContextToast;
  onDelete: () => void;
}

const Toast: React.FC<ToastProps> = function (props) {
  return (
    <div className={'Toast-box ' + props.toast.type}>
      {(function () {
        switch (props.toast.type) {
          case 'info':
            return <img className="Toast-icon" src="/toast-icons/info.png" />;
          case 'success':
            return (
              <img className="Toast-icon" src="/toast-icons/success.png" />
            );
          case 'error':
            return <img className="Toast-icon" src="/toast-icons/error.png" />;
        }
      })()}
      <p className="Toast-message">{props.toast.message}</p>
      <a
        className="Toast-delete_icon_container"
        onClick={() => props.onDelete()}
      >
        <img className="Toast-delete_icon" src="/delete.png" />
      </a>
    </div>
  );
};

interface ToastContainerProps {
  configure(data: ToastContextData): void;
}

export const ToastContainer: React.FC<ToastContainerProps> = function (props) {
  const [state, dispatch] = React.useReducer(toastContextReducer, {
    toasts: [],
  });

  props.configure({ state, dispatch });

  return (
    <div>
      <div className="ToastContainer-container">
        {state.toasts.map((toast) => (
          <Toast
            toast={toast}
            onDelete={() => dispatch({ type: 'deleteToast', id: toast.id })}
          />
        ))}
      </div>
    </div>
  );
};
