import * as React from 'react';
import { Route, Redirect, Switch, withRouter, RouteProps } from 'react-router';
import { NavigatorWithRouter } from '../Navigator';
import { PlaceListPage } from './PlaceListPage';
import { PlaceCreatePage } from './PlaceCreatePage';
import { PlacePage } from './PlacePage';
import { MyPage } from './MyPage';
import { AdminPage } from './AdminPage';
import { AdminCreateAccountPage } from './AdminCreateAccountPage';
import { AdminCreateCustomerPage } from './AdminCreateCustomerPage';
import { AccountCreatePasswordPage } from './AccountCreatePasswordPage';
import { AdminCreateFramePage } from './AdminCreateFramePage';
import { ConfigCreateShapeTilePage } from './AdminCreateShapeTilePage';
import { ConfigPage } from './ConfigPage';
import { NotFoundPage } from './NotFoundPage';

/**
 * トップページ
 */
export const IndexPage: React.FC = function () {
  return (
    <div className="App-content">
      <div className="App-navigator">
        <NavigatorWithRouter />
      </div>
      <main className="App-main">
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/dashboard" />}
          />
          <Route exact path="/places" component={() => <PlaceListPage />} />
          <Route
            exact
            path="/places/new"
            component={() => <PlaceCreatePage />}
          />
          <Route
            path="/places/:id"
            component={withRouter((props) => (
              <PlacePage {...props} />
            ))}
          />
          <Route exact path="/dashboard" component={() => <MyPage />} />\
          <Route
            exact
            path="/password/edit"
            component={withRouter((props) => (
              <AccountCreatePasswordPage backPath="/dashboard" target="me" />
            ))}
          />
          <Route exact path="/configs" component={() => <ConfigPage />} />
          <Route exact path="/admin" component={() => <AdminPage />} />
          <Route
            exact
            path="/admin/accounts/new"
            component={() => <AdminCreateAccountPage />}
          />
          <Route
            exact
            path="/admin/accounts/:accountId/edit"
            component={withRouter((props) => (
              <AdminCreateAccountPage
                existingId={parseInt(props.match.params.accountId)}
              />
            ))}
          />
          <Route
            exact
            path="/admin/accounts/:accountId/password/edit"
            component={withRouter((props) => (
              <AccountCreatePasswordPage
                backPath="/admin"
                target={parseInt(props.match.params.accountId)}
              />
            ))}
          />
          <Route
            exact
            path="/admin/clients/new"
            component={() => <AdminCreateCustomerPage />}
          />
          <Route
            exact
            path="/admin/clients/:customerId/edit"
            component={withRouter((props) => (
              <AdminCreateCustomerPage
                existingId={parseInt(props.match.params.customerId)}
              />
            ))}
          />
          <Route
            exact
            path="/admin/frames/new"
            component={() => <AdminCreateFramePage />}
          />
          <Route
            exact
            path="/admin/frames/:frameId/edit"
            component={withRouter((props) => (
              <AdminCreateFramePage
                existingId={parseInt(props.match.params.frameId)}
              />
            ))}
          />
          <Route
            exact
            path="/admin/shape_tiles/new"
            component={() => <ConfigCreateShapeTilePage />}
          />
          <Route
            exact
            path="/admin/shape_tiles/:frameId/edit"
            component={withRouter((props) => (
              <ConfigCreateShapeTilePage
                existingId={parseInt(props.match.params.frameId)}
              />
            ))}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </main>
    </div>
  );
};
