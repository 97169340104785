import * as React from 'react';
import { SketchPicker } from 'react-color';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  useUpdatePlaceSortMutation,
  useGetPlaceSortLazyQuery,
} from '../generated/graphql';
import { Loading } from '../Loading';
import { ToastContext } from '../components/Toast';
import { GraphQLMutationErrorDialog } from '../components/Dialogs';
import { Button, UpdateButton } from '../components/Button';
import { Input } from '../components/Input';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import './PlaceCreateSortPage.css';

type PlaceCreateSortPageProps = RouteComponentProps<{ placeId: string }> & {
  existingId?: number;
};

export const PlaceCreateSortPage: React.FC<PlaceCreateSortPageProps> = function (
  props
) {
  const placeId = parseInt(props.match.params.placeId);
  const history = useHistory();
  const toastContext = React.useContext(ToastContext);
  const validation = useValidation(ValidationRules.PlaceSort);
  const [name, setName] = validation.useState('name', '');
  const [color, setColor] = React.useState('');
  const [getPlaceSort, getPlaceSortResult] = useGetPlaceSortLazyQuery({
    onCompleted(data) {
      setName(data.getPlaceSort.name);
      setColor(data.getPlaceSort.color);
    },
  });
  const [addPlaceSort, addPlaceSortResult] = useUpdatePlaceSortMutation({
    onCompleted() {
      toastContext.showToast('補修種別を作成しました', 'success', 10000);
    },
  });

  if (addPlaceSortResult.called) {
    if (addPlaceSortResult.error) {
      return <GraphQLMutationErrorDialog error={addPlaceSortResult.error} />;
    }
    if (addPlaceSortResult.loading || !addPlaceSortResult.data) {
      return <Loading />;
    }

    history.push(`/places/${placeId}/sorts`);
  }

  if (props.existingId && !getPlaceSortResult.called) {
    getPlaceSort({
      variables: { placeSortId: props.existingId },
    });
  }

  if (getPlaceSortResult.called) {
    if (getPlaceSortResult.error) {
      return <GraphQLMutationErrorDialog error={getPlaceSortResult.error} />;
    }
    if (getPlaceSortResult.loading || !getPlaceSortResult.data) {
      return <Loading />;
    }
  }

  return (
    <div className="PlaceCreateSortPage-container">
      <h1>補修種別作成</h1>
      <div className="App-form">
        <div className="App-input_container PlaceCreateSortPage-input_container">
          <label className="App-label">補修種別名</label>
          <Input
            type="string"
            onChange={(event) => setName(event.target.value)}
            value={name}
            validationMessage={validation.get('name')}
          />
        </div>
        <div className="App-input_container PlaceCreateSortPage-input_container">
          <label className="App-label">補修種別の色</label>
          <SketchPicker
            color={color}
            onChange={(color) => setColor(color.hex)}
          />
        </div>
        <div className="PlaceCreateSortPage-button_container">
          <UpdateButton
            create={!props.existingId}
            disabled={validation.isInvalid}
            onClick={() => {
              addPlaceSort({
                variables: {
                  input: {
                    id: props.existingId,
                    place_id: placeId,
                    name: name,
                    color: color,
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
