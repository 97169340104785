interface Marker {
  id: number;
  revision: number;
  deleted: boolean;
}

export function getMarkerUsages(markers: Marker[]) {
  const markerMap: {
    [key: number]: { revision: number; deleted: boolean }[];
  } = {};
  for (const marker of markers) {
    if (!markerMap[marker.id]) {
      markerMap[marker.id] = [];
    }
    markerMap[marker.id].push(marker);
  }

  let used = 0;
  for (const markerId in markerMap) {
    const markers = markerMap[markerId];

    let latestMarker = markers[0];
    for (const marker of markers.slice(1)) {
      if (marker.revision > latestMarker.revision) {
        latestMarker = marker;
      }
    }

    if (!latestMarker.deleted) {
      used++;
    }
  }

  return {
    used,
    total: markers.length,
  };
}
