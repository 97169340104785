import * as React from 'react';
import { useHistory } from 'react-router';
import { ApolloError } from '@apollo/client';
import {
  useClonePlaceMutation,
  useGetAccountsQuery,
  useGetCustomersQuery,
  useGetPlaceLazyQuery,
  useGetPlaceNamesLazyQuery,
  useUpdatePlaceMutation,
} from '../generated/graphql';
import {
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import { Loading } from '../Loading';
import './PlaceCreatePage.css';
import { useValidation } from '../utils/Validation';
import { ValidationRules } from '../utils/ValidationRules';
import { Input, Checkbox } from '../components/Input';
import { Button, UpdateButton } from '../components/Button';

interface PlaceCreatePageProps {
  existingId?: number;
}

interface Account {
  id: number;
  name: string;
  screen_id: string;
}

export const PlaceCreatePage: React.FC<PlaceCreatePageProps> = function (
  props
) {
  const history = useHistory();
  const validation = useValidation(ValidationRules.Place);

  const [copy, setCopy] = React.useState(false);
  const [copySrc, setCopySrc] = React.useState<number | null>(null);
  const [copyCodes, setCopyCodes] = React.useState(false);
  const [copySorts, setCopySorts] = React.useState(false);
  const [copyFaces, setCopyFaces] = React.useState(false);

  const [name, setName] = validation.useState('name', '');
  const [chiefName, setChiefName] = validation.useState('chiefName', '');
  const [customerId, setCustomerId] = React.useState<number>(0);
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [lv1Accounts, setLv1Accounts] = React.useState<Account[]>([]);
  const [selectedAccountId, setSelectedAccountId] = React.useState<number>(-1);

  const customerListResult = useGetCustomersQuery();
  const accountListResult = useGetAccountsQuery({
    onCompleted(data) {
      const lv1Accounts: Account[] = data.getAccounts.filter(
        (a) => a.authority.level < 2
      );
      setLv1Accounts(lv1Accounts);
      if (lv1Accounts.length > 0) {
        setSelectedAccountId(lv1Accounts[0].id);
      }
    },
  });
  const [getPlace, getPlaceResult] = useGetPlaceLazyQuery({
    onCompleted(data) {
      const place = data.getPlace;
      setName(place.name);
      setChiefName(place.chief_name);
      setCustomerId(place.client_id);
      setAccounts(place.authorized_workers);
    },
  });
  const [getPlaceNames, getPlaceNamesResult] = useGetPlaceNamesLazyQuery({
    onCompleted(data) {
      if (data.getPlaces.length == 0) {
        return;
      }
      setCopySrc(data.getPlaces[0].id);
    },
  });

  const [fetching, setFetching] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState<ApolloError>();
  const [addPlace] = useUpdatePlaceMutation({
    onCompleted() {
      setFetching(false);
      setCompleted(true);
    },
    onError(error) {
      setFetching(false);
      setError(error);
    },
  });
  const [clonePlace] = useClonePlaceMutation({
    onCompleted() {
      setFetching(false);
      setCompleted(true);
    },
    onError(error) {
      setFetching(false);
      setError(error);
    },
  });

  if (props.existingId != undefined) {
    if (!getPlaceResult.called) {
      getPlace({
        variables: {
          placeId: props.existingId,
        },
      });
    }

    if (getPlaceResult.error) {
      return <GraphQLErrorDialog error={getPlaceResult.error} />;
    }

    if (getPlaceResult.loading || !getPlaceResult.data) {
      return <Loading />;
    }
  }

  if (accountListResult.loading || !accountListResult.data) {
    return <Loading />;
  }
  if (customerListResult.loading || !customerListResult.data) {
    return <Loading />;
  }

  // 作業者アカウントの一覧

  if (error != undefined) {
    return (
      <GraphQLMutationErrorDialog
        error={error}
        onOk={() => {
          setCompleted(false);
          setError(undefined);
        }}
      />
    );
  }

  if (fetching) {
    return <Loading />;
  }

  if (completed) {
    if (props.existingId) {
      history.push('/places/' + props.existingId);
    } else {
      history.push('/places');
    }
  }

  function isInvalid() {
    if (copy) {
      return validation.isInvalidProperty('name');
    }

    return validation.isInvalid;
  }

  return (
    <div className="PlaceCreatePage-container">
      <Loading
        open={getPlaceNamesResult.called && getPlaceNamesResult.loading}
      />
      <h1>{props.existingId ? '物件情報変更' : '物件新規作成'}</h1>
      {(function () {
        if (props.existingId) {
          return;
        }

        return (
          <div>
            <Checkbox
              value={copy}
              onChange={(v) => {
                if (!getPlaceNamesResult.called) {
                  getPlaceNames();
                }
                setCopy(v);
              }}
            />
            <label>物件情報をコピーする</label>
          </div>
        );
      })()}
      <div className="PlaceCreatePage-form">
        <div className="App-input_container PlaceCreatePage-input_container">
          <label className="App-label">物件名</label>
          <Input
            className="PlaceCreatePage-place_name_input"
            type="string"
            value={name}
            validationMessage={validation.get('name')}
            onChange={(event) => {
              const v = event.target.value;
              setName(() => v);
            }}
          />
        </div>
        {(function () {
          if (copy) {
            if (
              getPlaceNamesResult.loading ||
              !getPlaceNamesResult.data ||
              !copySrc
            ) {
              return;
            }

            if (getPlaceNamesResult.data.getPlaces.length == 0) {
              return <p>物件が登録されていません。</p>;
            }

            return (
              <div>
                <div className="PlaceCreatePage-input_container">
                  <select
                    value={copySrc}
                    onChange={(event) =>
                      setCopySrc(parseInt(event.target.value))
                    }
                  >
                    {getPlaceNamesResult.data.getPlaces.map((p) => (
                      <option value={p.id}>{p.name}</option>
                    ))}
                  </select>
                </div>
                <div className="PlaceCreatePage-input_container">
                  <Checkbox
                    value={copyCodes}
                    onChange={(v) => setCopyCodes(v)}
                  />
                  <label>補修コード</label>
                </div>
                <div className="PlaceCreatePage-input_container">
                  <Checkbox
                    value={copySorts}
                    onChange={(v) => setCopySorts(v)}
                  />
                  <label>補修種別</label>
                </div>
                <div className="PlaceCreatePage-input_container">
                  <Checkbox
                    value={copyFaces}
                    onChange={(v) => setCopyFaces(v)}
                  />
                  <label>図面</label>
                </div>
              </div>
            );
          }

          return (
            <div>
              <div className="App-input_container PlaceCreatePage-input_container">
                <label className="App-label">責任者名</label>
                <Input
                  className="PlaceCreatePage-place_chief_name_input"
                  type="string"
                  value={chiefName}
                  validationMessage={validation.get('chiefName')}
                  onChange={(event) => {
                    const v = event.target.value;
                    setChiefName(() => v);
                  }}
                />
              </div>
              <div className="App-input_container PlaceCreatePage-input_container">
                <label className="App-label">クライアント</label>
                <select
                  className="PlaceCreatePage-client_select"
                  value={customerId}
                  onChange={(event) => {
                    const v = parseInt(event.target.value);
                    setCustomerId(() => v);
                  }}
                >
                  <option value="0">-</option>
                  {customerListResult.data.getCustomers.map((c) => (
                    <option value={c.id}>{c.name}</option>
                  ))}
                </select>
              </div>
              <div className="App-input_container PlaceCreatePage-input_container">
                <label className="App-label">作業者リスト</label>
                <table>
                  <thead></thead>
                  <tbody>
                    {accounts.map((account) => {
                      return (
                        <tr>
                          <td>
                            {account.name} ({account.screen_id})
                          </td>
                          <td className="App-table_button_cell">
                            <Button
                              color="red"
                              mini={true}
                              onClick={() => {
                                setAccounts(
                                  accounts.filter((a) => a.id != account.id)
                                );
                              }}
                            >
                              削除
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>
                        <select
                          className="PlaceCreatePage-client_select"
                          value={selectedAccountId}
                          onChange={(event) => {
                            setSelectedAccountId(parseInt(event.target.value));
                          }}
                        >
                          {lv1Accounts
                            .filter(
                              (lv1) =>
                                accounts.findIndex((a) => a.id === lv1.id) ===
                                -1
                            )
                            .map((a) => (
                              <option value={a.id}>
                                {a.name} ({a.screen_id})
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="App-table_button_cell">
                        <Button
                          mini={true}
                          onClick={() => {
                            var selectedAccount = lv1Accounts.find(
                              (a) => a.id === selectedAccountId
                            );
                            if (!selectedAccount) {
                              return;
                            }
                            setAccounts(accounts.concat([selectedAccount]));
                          }}
                        >
                          追加
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })()}
        <div className="PlaceCreatePage-button_container">
          <UpdateButton
            create={!props.existingId}
            disabled={isInvalid()}
            className="App-button PlaceCreatePage-create_button"
            onClick={() => {
              if (copy) {
                if (!copySrc) {
                  throw new Error('copySrc is null');
                }

                clonePlace({
                  variables: {
                    input: {
                      place_id: copySrc,
                      name,
                      codes: copyCodes,
                      sorts: copySorts,
                      faces: copyFaces,
                    },
                  },
                });
                return;
              }
              addPlace({
                variables: {
                  input: {
                    id: props.existingId,
                    name: name,
                    chief_name: chiefName,
                    customerId: customerId,
                    authorized_worker_ids: accounts.map((a) => a.id),
                  },
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
