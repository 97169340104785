import * as React from 'react';
import { AuthorityLabel } from '../components/AuthorityLabel';
import { Loading } from '../Loading';
import { useHistory } from 'react-router';
import {
  useGetAdminDataLazyQuery,
  useDeleteAccountMutation,
} from '../generated/graphql';
import { Button } from '../components/Button';
import {
  ConfirmDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import './AdminPage.css';
import { AppContext } from '../AppContext';

interface DeleteAccountTarget {
  id: number;
  screen_id: string;
  name: string;
}

/**
 * アプリケーション管理ページ
 */
export const AdminPage: React.FC = function () {
  const history = useHistory();
  const [
    deleteAccountTarget,
    setDeleteAccountTarget,
  ] = React.useState<DeleteAccountTarget>();
  const [getData, getDataResult] = useGetAdminDataLazyQuery();
  const [deleteAccount, deleteAccountResult] = useDeleteAccountMutation({
    onCompleted() {
      getData();
    },
  });

  if (deleteAccountResult.error != undefined) {
    return <GraphQLMutationErrorDialog error={deleteAccountResult.error} />;
  }

  if (!getDataResult.called) {
    getData();
  }

  if (
    getDataResult.loading ||
    !getDataResult.data ||
    deleteAccountResult.loading
  ) {
    return <Loading />;
  }

  return (
    <div className="AdminPage-container">
      {(function () {
        if (!deleteAccountTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={!!deleteAccountTarget}
            title="アカウント削除"
            message={
              <p>
                {deleteAccountTarget.name} ({deleteAccountTarget.screen_id})
                を削除しますか？
              </p>
            }
            onYes={() => {
              deleteAccount({
                variables: { accountId: deleteAccountTarget.id },
              });
              setDeleteAccountTarget(undefined);
            }}
            onNo={() => setDeleteAccountTarget(undefined)}
          />
        );
      })()}
      <h1>管理画面</h1>
      <div>
        <h2>ユーザー管理</h2>
        <Button onClick={() => history.push('/admin/accounts/new')}>
          ユーザー追加
        </Button>
        <table className="App-table">
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>名前</th>
              <th>所属</th>
              <th colSpan={2}>操作</th>
            </tr>
          </thead>
          <tbody>
            {getDataResult.data.getAccounts.map((a) => {
              return (
                <tr>
                  <td>
                    <AuthorityLabel authority={a.authority.level} />
                  </td>
                  <td>{a.screen_id}</td>
                  <td>{a.name}</td>
                  <td>{a.department}</td>
                  <td className="App-table_button_cell">
                    <Button
                      mini={true}
                      color={'white'}
                      onClick={() =>
                        history.push(`/admin/accounts/${a.id}/edit`)
                      }
                    >
                      編集
                    </Button>
                  </td>
                  <td className="App-table_button_cell">
                    <Button
                      mini={true}
                      color={'red'}
                      onClick={() => setDeleteAccountTarget(a)}
                    >
                      削除
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h2>クライアント管理</h2>
        <Button onClick={() => history.push('/admin/clients/new')}>
          クライアント追加
        </Button>
        <table className="App-table">
          <thead>
            <tr>
              <th>名前</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            {getDataResult.data.getCustomers.map((c) => {
              return (
                <tr>
                  <td>{c.name}</td>
                  <td className="App-table_button_cell">
                    <Button
                      mini={true}
                      color={'white'}
                      onClick={() =>
                        history.push(`/admin/clients/${c.id}/edit`)
                      }
                    >
                      編集
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
