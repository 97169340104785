import * as React from 'react';
import './ImageSelect.css';

interface IImageSelectOption {
  className?: string;
  selectedValue: string;
  value: string;
  onClick: (value: string) => void;
}

const ImageSelectOption: React.FC<IImageSelectOption> = function (props) {
  let className = 'ImageSelectOption-container';
  if (props.className) {
    className += ' ' + props.className;
  }
  if (props.selectedValue === props.value) {
    className += ' ImageSelectOption-selected';
  }

  return (
    <a onClick={() => props.onClick(props.value)}>
      <div className={className}>{props.children}</div>
    </a>
  );
};

interface ILineSelectProps {
  value: number;
  optionClassName?: string;
  onChange: (value: number) => void;
}

export const LineSelect: React.FC<ILineSelectProps> = function (props) {
  return (
    <div>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="0"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/line_normal.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="1"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/line_dotted.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="2"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/line_dashed.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="3"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/line_circle.png" />
      </ImageSelectOption>
    </div>
  );
};

interface IArrowEndSelectProps {
  value: number;
  optionClassName?: string;
  onChange: (value: number) => void;
}

export const ArrowEndSelect: React.FC<IArrowEndSelectProps> = function (props) {
  return (
    <div>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="0"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/arrow_end_none.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="1"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/arrow_end_arrow.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="2"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/arrow_end_circle.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="3"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/arrow_end_cross.png" />
      </ImageSelectOption>
    </div>
  );
};

interface IHatchSelectProps {
  value: number;
  optionClassName?: string;
  onChange: (value: number) => void;
}

export const HatchSelect: React.FC<IHatchSelectProps> = function (props) {
  return (
    <div>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="0"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_none.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="1"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_line.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="2"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_lineL.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="3"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_cross.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="4"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_crossL.png" />
      </ImageSelectOption>
      <ImageSelectOption
        className={props.optionClassName}
        selectedValue={props.value.toString()}
        value="5"
        onClick={(v) => props.onChange(parseInt(v))}
      >
        <img src="/hatch_fill.png" />
      </ImageSelectOption>
    </div>
  );
};
