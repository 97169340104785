import {
  IAdditionalField,
  ValueType,
} from '../components/create-place-code/AdditoinalFieldsInput';
import { FieldType } from '../components/create-place-code/DrawingObjectsInput';
import { IInputField } from '../components/create-place-code/InputFieldsInput';
import { IOutputField } from '../components/create-place-code/OutputFieldsInput';

function nameValidator(maxLength: number = 50) {
  return function (value: string) {
    if (!value) {
      return '必須入力項目です';
    }
    if (value.startsWith(' ') || value.startsWith('　')) {
      return '先頭に空白があります';
    }
    if (value.length > maxLength) {
      return maxLength + '文字以内で入力してください';
    }
  };
}

function additionalFieldValidator() {
  return function (field: IAdditionalField) {
    const nameValidation = nameValidator()(field.name);
    if (nameValidation) {
      return '名称: ' + nameValidation;
    }

    if (field.valueType == ValueType.Option) {
      if (field.options.length == 0) {
        return '選択肢が指定されていない項目があります';
      }

      for (const option of field.options) {
        const optionValidation = nameValidator()(option);
        if (optionValidation) {
          return '選択肢: ' + optionValidation;
        }
      }
    }
  };
}

function outputFieldValidator() {
  return function (field: IOutputField) {
    const nameValidation = nameValidator()(field.name);
    if (nameValidation) {
      return '名称: ' + nameValidation;
    }

    const formulatValidation = nameValidator(300)(field.formula);
    if (formulatValidation) {
      return '計算式: ' + formulatValidation;
    }
  };
}

export const ValidationRules = {
  Account: {
    name: nameValidator(),
    screenId(value: string) {
      if (!value) {
        return '必須入力項目です';
      }
      if (!value.match(/^[a-zA-Z][a-zA-Z0-9\.\-\_]*$/)) {
        return '半角英数字と「.」「-」「_」のみが利用できます（先頭は半角英数字のみ）';
      }
    },
    department: nameValidator(),
    password(value: string | undefined) {
      if (!value) {
        return '必須入力項目です';
      }
      if (value.length < 6) {
        return 'パスワードは6文字以上に設定してください';
      }
      if (!value.match(/^[a-zA-Z0-9\.\-\_]*$/)) {
        return '使用できない文字が含まれています';
      }
    },
  },
  Place: {
    name: nameValidator(),
    chiefName: nameValidator(),
  },
  PlaceCode: {
    name: nameValidator(),
    inputFields(fields: IInputField[]) {
      return undefined;
    },
    additionalFields(fields: IAdditionalField[]) {
      for (const field of fields) {
        const fieldValidation = additionalFieldValidator()(field);
        if (fieldValidation) {
          return fieldValidation;
        }
      }
    },
    outputFields(fields: IOutputField[]) {
      for (const field of fields) {
        const fieldValidation = outputFieldValidator()(field);
        if (fieldValidation) {
          return fieldValidation;
        }
      }
    },
    drawingObjects() {
      return undefined;
    },
  },
  PlaceSort: {
    name: nameValidator(),
  },
  Customer: {
    name: nameValidator(),
  },
  Tile: {
    name: nameValidator(),
    width() {
      return undefined;
    },
    height() {
      return undefined;
    },
    margin() {
      return undefined;
    },
  },
};
