import * as React from 'react';
import { AppContext } from '../AppContext';

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: ButtonColors;
  mini?: boolean;
}

type ButtonColors = 'normal' | 'red' | 'white';

export const Button: React.FC<IButtonProps> = function (props) {
  const color = props.color ?? 'normal';
  let colorClassName: string;
  switch (color) {
    case 'red':
      colorClassName = ' App-button_color_red';
      break;
    case 'white':
      colorClassName = ' App-button_color_gray';
      break;
    case 'normal':
    default:
      colorClassName = ' App-button_color_green';
      break;
  }

  let miniClassName = '';
  if (props.mini) {
    miniClassName = ' App-button_mini';
  }

  let className = 'App-button';
  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <button {...props} className={className + colorClassName + miniClassName}>
      {props.children}
    </button>
  );
};

interface UpdateButtonProps extends IButtonProps {
  create: boolean;
}

export const UpdateButton: React.FC<UpdateButtonProps> = function (props) {
  const message = props.create ? '作成' : '変更';
  return <Button {...props}>{message}</Button>;
};

interface IAuthButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: ButtonColors;
  mini?: boolean;
  authLv: number;
}

export const AuthButton: React.FC<IAuthButtonProps> = function (props) {
  const appContext = React.useContext(AppContext);
  const me = appContext.state.me;

  if (!me) {
    return <div></div>;
  }

  if (me.authority.level < props.authLv) {
    return <div></div>;
  }

  return <Button {...props}>{props.children}</Button>;
};
