import * as React from 'react';
import { Loading } from '../Loading';
import { useHistory } from 'react-router';
import {
  useGetConfigLazyQuery,
  useDeleteFrameMutation,
  useDeleteShapeTileMutation,
} from '../generated/graphql';
import { AuthButton, Button } from '../components/Button';
import {
  ConfirmDialog,
  GraphQLErrorDialog,
  GraphQLMutationErrorDialog,
} from '../components/Dialogs';
import './AdminPage.css';
import master from '../Master';
import * as Const from '../Const';

interface DeleteFrameTarget {
  id: number;
  name: string;
}

interface DeleteShapeTileTarget {
  id: number;
  name: string;
}

/**
 * 共通設定ページ
 */
export const ConfigPage: React.FC = function () {
  const history = useHistory();
  const [getConfig, getConfigResult] = useGetConfigLazyQuery();
  const [deleteFrame, deleteFrameResult] = useDeleteFrameMutation({
    onCompleted() {
      getConfig();
    },
  });
  const [deleteShapeTile, deleteShapeTileResult] = useDeleteShapeTileMutation({
    onCompleted() {
      getConfig();
    },
  });
  const [
    deleteFrameTarget,
    setDeleteFrameTarget,
  ] = React.useState<DeleteFrameTarget>();
  const [
    deleteShapeTileTarget,
    setDeleteShapeTileTarget,
  ] = React.useState<DeleteShapeTileTarget>();

  if (!getConfigResult.called) {
    getConfig();
  }

  if (getConfigResult.error) {
    return <GraphQLErrorDialog error={getConfigResult.error} />;
  }

  if (deleteFrameResult.error != undefined) {
    return <GraphQLMutationErrorDialog error={deleteFrameResult.error} />;
  }

  if (deleteShapeTileResult.error != undefined) {
    return <GraphQLMutationErrorDialog error={deleteShapeTileResult.error} />;
  }

  if (
    getConfigResult.loading ||
    !getConfigResult.data ||
    deleteFrameResult.loading ||
    deleteShapeTileResult.loading
  ) {
    return <Loading />;
  }

  return (
    <div className="ConfigPage-container">
      {(function () {
        if (!deleteFrameTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            title="図枠削除"
            message={<p>{deleteFrameTarget.name}を削除しますか？</p>}
            onYes={() => {
              deleteFrame({
                variables: { frameId: deleteFrameTarget.id },
              });
              setDeleteFrameTarget(undefined);
            }}
            onNo={() => setDeleteFrameTarget(undefined)}
          />
        );
      })()}
      {(function () {
        if (!deleteShapeTileTarget) {
          return;
        }

        return (
          <ConfirmDialog
            open={true}
            title="タイル削除"
            message={<p>{deleteShapeTileTarget.name}を削除しますか？</p>}
            onYes={() => {
              deleteShapeTile({
                variables: { shapeTileId: deleteShapeTileTarget.id },
              });
              setDeleteShapeTileTarget(undefined);
            }}
            onNo={() => setDeleteShapeTileTarget(undefined)}
          />
        );
      })()}
      <h1>共通設定</h1>
      <h2>タイル設定</h2>
      <Button onClick={() => history.push('/admin/shape_tiles/new')}>
        タイル追加
      </Button>
      <table className="App-table">
        <thead>
          <tr>
            <th>名前</th>
            <th>幅</th>
            <th>高さ</th>
            <th>間隔</th>
            <th colSpan={2}>操作</th>
          </tr>
        </thead>
        <tbody>
          {getConfigResult.data.getConfig.tiles.map((tile) => {
            return (
              <tr>
                <td>{tile.name}</td>
                <td>{tile.width}</td>
                <td>{tile.height}</td>
                <td>{tile.margin}</td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color={'white'}
                    onClick={() =>
                      history.push(`/admin/shape_tiles/${tile.id}/edit`)
                    }
                  >
                    編集
                  </AuthButton>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color="red"
                    onClick={() => setDeleteShapeTileTarget(tile)}
                  >
                    削除
                  </AuthButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h2>図枠設定</h2>
      <Button onClick={() => history.push('/admin/frames/new')}>
        図枠追加
      </Button>
      <table className="App-table">
        <thead>
          <tr>
            <th>名前</th>
            <th colSpan={2}>ファイル</th>
            <th colSpan={2}>操作</th>
          </tr>
        </thead>
        <tbody>
          {getConfigResult.data.getConfig.frames.map((frame) => {
            return (
              <tr>
                <td>{frame.name}</td>
                <td>
                  <a href={`${Const.API_URL}${frame.dxf_url}`}>
                    {frame.dxf_file_name}
                  </a>
                </td>
                <td>
                  <ul>
                    {frame.dxf_images.map((image) => (
                      <li>
                        <a href={`${Const.API_URL}${image.file_url}`}>
                          {image.file_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    mini={true}
                    color={'white'}
                    onClick={() =>
                      history.push(`/admin/frames/${frame.id}/edit`)
                    }
                  >
                    編集
                  </AuthButton>
                </td>
                <td className="App-table_button_cell">
                  <AuthButton
                    authLv={2}
                    color="red"
                    mini={true}
                    onClick={() => setDeleteFrameTarget(frame)}
                  >
                    削除
                  </AuthButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
