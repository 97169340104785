import React, { useState } from 'react';
import { Routing } from './Routing';
import {
  ToastContainer,
  ToastContext,
  ToastContextClass,
  ToastContextData,
} from './components/Toast';
import './stylesheets/App.css';

const App: React.FC = function () {
  // State や Context 使うと App 以下のコンポーネントが
  // すべて再レンダリングされるのでJavaScript の変数を使って無理やり回避する

  var toastContextData: ToastContextData;

  const configure = function (data: ToastContextData) {
    toastContextData = data;
  };

  const context = new ToastContextClass(function () {
    return toastContextData;
  });

  return (
    <div className="App">
      <ToastContext.Provider value={context}>
        <Routing />
      </ToastContext.Provider>
      <ToastContainer configure={configure} />
    </div>
  );
};

export default App;
